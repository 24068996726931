import React from "react";
import cx from "classnames";
import {useTranslation} from "react-i18next";
import {useFormContext} from "react-hook-form";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from "@mui/material/Divider";
import CheckIcon from "@mui/icons-material/Check";

import CodeServices from "./components/code-services";
import {COLORS} from "../../../../../../../../../config/constants";

import {CertificationFormType} from "../../index";
import {
  useLazyDeclarationSummaryByOrderIdQuery,
  useSetCertificationCenterMutation, useSetCompletedByOrderIdMutation,
} from "../../../../../../../../../api/calc";
import {useGetCalcIdFromRoute} from "../../../../../../../../../utils/hooks";

import './certificate-company-card.scss'
import {numberWithSpaces} from "../../../../../../../../../utils/helpers";

type Props = {
    isActive?: boolean
    center?: CertificationCenter
    currentCenter?: CurrentCertificationCenter
    onClose?(): void
    handleComplete?(): void
}

const CompanyCard = ({ isActive, center, currentCenter, onClose, handleComplete } : Props ) => {

  const { t } = useTranslation()

  const orderId = useGetCalcIdFromRoute()

  const [ setCertificationCenter ] = useSetCertificationCenterMutation()

  const { getValues } = useFormContext<CertificationFormType>()

  const [ setCompleted ] = useSetCompletedByOrderIdMutation()

  const [ trigger, { data, isFetching, error } ] = useLazyDeclarationSummaryByOrderIdQuery();

  const handleChoose = async () => {

    if (!center) {
      return;
    }

    const data = getValues().codes.map(item => ({
      code: item.code,
      has_certificate: item.has_certificate,
      has_code_compliance: item.has_code_compliance,
      has_declaration_compliance: item.has_declaration_compliance,
      has_certificate_state_registration: item.has_certificate_state_registration,
      ...!item.has_certificate && {
        certification_center: {
          id: center.id
        },
        cert_total: center.cost_total,
        codes: center.codes
      }
    }));

    await setCertificationCenter({
      orderId,
      body: data
    });

    await setCompleted({
      orderId,
      certification: true
    })

    if (handleComplete) {
      handleComplete();
    }

    if (onClose) {
      onClose();
    }

    trigger(orderId);

  }

  const name = center?.name || currentCenter?.certification_center?.name;

  const costTotal = center?.cost_total || currentCenter?.cert_total;

  const codes = center?.codes || currentCenter?.codes;

  return <Card className={cx('certificate-company-card', {
    'certificate-company-card_active': isActive,
  })} sx={{ background: isActive ? COLORS.LIME : COLORS.WHITE, borderRadius: 0, boxShadow: 'none' }}>
    <CardContent sx={{
      padding: 0, '&:last-child': {
        paddingBottom: 0
      }
    }}>
      <div className='certificate-company-card__main-content'>
        <div className='certificate-company-card__left'>
          <span className='certificate-company-card__logo'>{name?.[0]}</span>
          <Typography variant='subtitle2'>
            {name}
          </Typography>
        </div>
        <div className={cx('certificate-company-card__right', {
          'certificate-company-card__right_active': isActive,
        })}>
          <Typography variant='h6' sx={{ lineHeight: 1.2, marginRight: 2 }}>
            {`${numberWithSpaces(costTotal)} USD`}
          </Typography>
          <Button
            variant='outlined'
            fullWidth
            endIcon={isActive ? undefined : <ExpandMoreIcon sx={{color: COLORS.BLUE}}/>}
            startIcon={isActive ? <CheckIcon /> : undefined}
            sx={{
              width: isActive ? 145 : 155,
              '&.Mui-disabled': {
                backgroundColor: COLORS.SLIGHTLY_GRAY,
                color: COLORS.LIGHT_TEXT_DISABLED_OPACITY,
                border: 'none'
              }
            }}
            onClick={handleChoose}
            disabled={isActive}
          >{isActive ? t('common__text__selected') : t('actions__text__choose')}</Button>
        </div>
      </div>
      <Divider/>
      {codes?.map((code, index) => <CodeServices key={index} code={code} />)}
    </CardContent>
  </Card>
}

export default CompanyCard
