import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import {useForm} from "react-hook-form";

import {CertificationType} from "../../index";
import RadioTemplate from "../radio-template";
import {ADORNMENTS} from "../../../../../../../config/constants";
import {checkTwoDecimalsAfterDot} from "../../../../../../../utils/validation";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import Typography from "@mui/material/Typography";

import {useSetCompletedByOrderIdMutation, useSetManualCertificationMutation} from "../../../../../../../api/calc";
import {useGetCalcIdFromRoute} from "../../../../../../../utils/hooks";
import Completed from "../../../../../../common/completed";

import './manual.scss';
import {numberWithSpaces} from "../../../../../../../utils/helpers";

enum CERTIFICATION_MANUAL_FORM {
  CERT_TOTAL_MANUAL = 'cert_total_manual',
}

type CertificationManualForm = {
  cert_total_manual: number
}

type Props = {
  isExpanded: boolean
  value?: number
  isActive: boolean
  handleChangeAccordion(value: CertificationType, isExpanded: boolean): void
  handleComplete(): void
  handleChange(): void
}

const CertificationManual = ({ isExpanded, handleChangeAccordion, handleComplete, handleChange, value, isActive }: Props) => {

  const { t } = useTranslation()

  const orderId = useGetCalcIdFromRoute()

  const [ shouldShowCompletedInfo, setShouldShowCompletedInfo ] = useState(Boolean(value));

  const errorNumber = t('errors__message__incorrectValue');

  const [ setManualCertification ] = useSetManualCertificationMutation()

  const [ setCompleted ] = useSetCompletedByOrderIdMutation()

  const { register, handleSubmit, setValue, formState: { errors, isValid } } = useForm<CertificationManualForm>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      [CERTIFICATION_MANUAL_FORM.CERT_TOTAL_MANUAL]: value,
    }
  })

  useEffect(() => {
    if (value) {
      // @ts-ignore
      setValue(CERTIFICATION_MANUAL_FORM.CERT_TOTAL_MANUAL, value);
      setShouldShowCompletedInfo(true);
    }
    else {
      setShouldShowCompletedInfo(false);
    }
  }, [ value ])

  const handleOpenAccordion = (isExpanded: boolean) => {
    handleChangeAccordion(CertificationType.MANUAL, isExpanded);
  }

  const onSubmit = handleSubmit(async values => {
    await setManualCertification({
      orderId,
      cert_total_manual: values.cert_total_manual
    });

    await setCompleted({
      orderId,
      certification: true
    })
    setShouldShowCompletedInfo(true);
    handleComplete();
  })

  const getCompletedInfo = () => [
    {
      label: t('dictionaries__text__certification__priceManual'),
      value: `${numberWithSpaces(value)} USD`
    }
  ];

  return <div className='certification-manual'>
    <RadioTemplate
      isActive={isActive}
      title={<Typography variant='h6' sx={{
        fontWeight: '500',
        marginLeft: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
      }}>
        {t('dictionaries__text__certification__iKnowTheCostOfCertification')}
        <span className='certification-manual__total'>
          {value ? `${numberWithSpaces(value)} USD` : undefined}
        </span>
      </Typography>}
      value={CertificationType.MANUAL}
      isExpanded={isExpanded}
      handleChangeAccordion={handleOpenAccordion}
    >
      {shouldShowCompletedInfo ? (
        <Completed
          info={getCompletedInfo()}
          onEdit={() => setShouldShowCompletedInfo(false)}
          isFullRow
          caption={<></>}
        />
      ) : <>
        <div className='certification-manual__container'>
          <TextField
            {...register(CERTIFICATION_MANUAL_FORM.CERT_TOTAL_MANUAL, {
              required: t('common__text__isRequired'),
              validate: (value: string) => checkTwoDecimalsAfterDot(Number(value), errorNumber)
            })}
            error={Boolean(errors[CERTIFICATION_MANUAL_FORM.CERT_TOTAL_MANUAL])}
            helperText={errors[CERTIFICATION_MANUAL_FORM.CERT_TOTAL_MANUAL]?.message}
            variant='outlined'
            label={t('dictionaries__text__certification__priceManual')}
            InputLabelProps={{shrink: true}}
            InputProps={{
              endAdornment: ADORNMENTS.PRICE,
            }}
            sx={{width: '306px'}}
            onBlur={handleChange}
          />
        </div>
        <Button
          variant='contained'
          startIcon={<CheckIcon/>}
          sx={{marginTop: 3}}
          fullWidth
          disabled={!isValid}
          onClick={onSubmit}
        >
          {t('dictionaries__text__certification__saveTheCostOfCertification')}
        </Button>
      </>}
    </RadioTemplate>
  </div>
}

export default CertificationManual
