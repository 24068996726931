import {useTranslation} from "react-i18next";
import {numberWithSpaces} from "../../../../../utils/helpers";

export const getBankCommissionsInfo = (summaryBankCommissions?: BankCommissionsSummary, totalCommissionPayment?: number) => {

  const { t } = useTranslation();

  if (!summaryBankCommissions) {
    return []
  }

  const invoicesBankCommissions = summaryBankCommissions.invoices?.map((invoice, index) => [
    {
      label: `${index + 1}. ${t('common__text__invoice')}`,
      value: `№ ${invoice.invoice_num}`
    },
    {
      label: `${t('calc__text__bankCommissions__payment')}`,
      value: `${numberWithSpaces(invoice?.invoice_summary?.total_payment_amount)} USD`
    },
    {
      label: `${t('calc__text__bankCommissions__bankCommissionForTheTransfer')}`,
      value: `${numberWithSpaces(invoice?.invoice_summary?.total_banking_commissions_usd)} USD`
    },
    {
      label: `${t('calc__text__bankCommissions__currencyControlCommission')}`,
      value: `${numberWithSpaces(invoice?.invoice_summary?.total_currency_control_fees_usd)} USD`
    },
    {
      label: `${t('calc__text__bankCommissions__totalToBePaid')}`,
      value: `${numberWithSpaces(invoice?.invoice_summary?.total_payment_amount_with_commissions)} USD`
    }
  ]);

  return [
    ...invoicesBankCommissions,
    [
      {
        label: t('common__text__inTotal'),
        value: `${numberWithSpaces(totalCommissionPayment)} USD`
      }
    ]
  ]
}
