import React, {useState} from "react";
import {useTranslation} from "react-i18next";

import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import {INFORMERS_CODES} from "../../../../../../../../../../../config/constants";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import {useInformers} from "../../../../../../../../../../../api/informers";
import {numberWithSpaces} from "../../../../../../../../../../../utils/helpers";

type Props = {
    declaration: Declaration
}

const CustomsCost = ({ declaration } : Props) => {

  const [ isTooltipCustomsCostOpened, setIsCustomsCostOpened ] = useState(false)

  const openTooltipCustomsCost = () => setIsCustomsCostOpened(true)

  const closeTooltipCustomsCost = () => setIsCustomsCostOpened(false)

  const informers = useInformers();

  return (
    <ClickAwayListener onClickAway={closeTooltipCustomsCost}>
      <Tooltip
        placement='top'
        open={isTooltipCustomsCostOpened}
        title={informers?.[INFORMERS_CODES.CUSTOMS_COST]}
        arrow
      >
        <span
          onClick={openTooltipCustomsCost}
          className='customs-declaration__text-with-tooltip'
        >{`${numberWithSpaces(declaration.lowin || declaration.customsValue)} USD`}</span>
      </Tooltip>
    </ClickAwayListener>
  )
}

export default CustomsCost
