import React from "react";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {COLORS} from "../../../../../../../config/constants";

import InvoiceTitle from "./components/invoiceTitle";
import InvoiceDetails from "./components/invoiceDetails";

import './invoice.scss';

type Props = {
    invoice: InvoiceParamsOutput,
    index: number
    refetch(): void
}

const Invoice = ({invoice, index, refetch}: Props) => {

  return <div className='invoice'>
    <Accordion sx={{
      marginBottom: 0,
      border: `1px solid ${COLORS.SLIGHTLY_GRAY}`,
      boxShadow: 'none',
    }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon/>}
        sx={{padding: '12px 24px'}}
      >
        <InvoiceTitle invoice={invoice} index={index}/>
      </AccordionSummary>
      <AccordionDetails sx={{padding: 3}}>
        {invoice.price_total && (
          <InvoiceDetails
            invoiceNumber={invoice.invoice_num}
            invoiceId={invoice.id.toString()}
            refetch={refetch}
            invoiceAmount={invoice.price_total}
          />
        )}
      </AccordionDetails>
    </Accordion>
  </div>
}

export default Invoice
