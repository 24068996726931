import React, {ReactNode} from "react";

import {useTranslation} from "react-i18next";
import CheckIcon from "@mui/icons-material/Check";
import Button from "@mui/material/Button";
import Tooltip from "../../../../../../../../common/tooltip";
import './../../summary.scss'

import {INFORMERS_CODES} from "../../../../../../../../../config/constants";
import {useInformers} from "../../../../../../../../../api/informers";
import Typography from "@mui/material/Typography";
import COLORS from "../../../../../../../../../styles/colors.scss";

type Props =  {
    summary: SummaryParamsOutput
    handleSave?(): void
}

const CombinedCargo = ({summary, handleSave}: Props) => {

  const { t } = useTranslation()

  const informers = useInformers();

  return (<div className='summary-packaging__block-delivery'>
    <div className='summary-packaging__buttons'>
      <span className='summary-packaging__tooltipContainer'>
        <Tooltip content={informers[INFORMERS_CODES.VOLUME_WEIGHT]}/>
      </span>
    </div>
    <Typography variant='subtitle2' sx={{ fontSize: '16px', marginTop: 2, marginBottom: '18px', color: COLORS.BLACK_DARK }}>{t('calc__text__packaging__volumetricWeight')}</Typography>
    <ul className='summary-packaging__list'>
      <li className='summary-packaging__list-item'>
        <Typography variant='body2'
          sx={{color: COLORS.LIGHT_TEXT_SECONDARY}}>{t('calc__text__delivery__airfreight')}</Typography>
        <Typography variant='body2'
          sx={{color: COLORS.BLACK_DARK}}>{`${summary?.volume_weight.avia || 0} ${t('units__text__kg')}`}/{t('units__text__meter')}<sup>3</sup></Typography>
      </li>
      <li className='summary-packaging__list-item'>
        <Typography variant='body2'
          sx={{color: COLORS.LIGHT_TEXT_SECONDARY}}>{t('calc__text__delivery__trucking')}</Typography>
        <Typography variant='body2'
          sx={{color: COLORS.BLACK_DARK}}>{`${summary?.volume_weight.auto || 0} ${t('units__text__kg')}`}/{t('units__text__meter')}<sup>3</sup></Typography>
      </li>
      <li className='summary-packaging__list-item'>
        <Typography variant='body2'
          sx={{color: COLORS.LIGHT_TEXT_SECONDARY}}>{t('calc__text__delivery__seafreight')}</Typography>
        <Typography variant='body2'
          sx={{color: COLORS.BLACK_DARK}}>{`${summary?.volume_weight.sea || 0} ${t('units__text__kg')}`}/{t('units__text__meter')}<sup>3</sup></Typography>
      </li>
      <li className='summary-packaging__list-item'>
        <Typography variant='body2'
          sx={{color: COLORS.LIGHT_TEXT_SECONDARY}}>{t('calc__text__delivery__railwayFreight')}</Typography>
        <Typography variant='body2'
          sx={{color: COLORS.BLACK_DARK}}>{`${summary?.volume_weight.rails || 0} ${t('units__text__kg')}`}/{t('units__text__meter')}<sup>3</sup></Typography>
      </li>
    </ul>
    {handleSave && <Button
      variant='contained'
      fullWidth
      onClick={handleSave}
      startIcon={<CheckIcon/>}
      sx={{marginTop: 3}}
    >{t('actions__text__confirmDeliveryMethodCollectShipment')}</Button>}
  </div>)
}

export default CombinedCargo
