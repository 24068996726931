import React, {useEffect, useRef, useState} from 'react'
import debounce from 'lodash/debounce'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from "react-i18next"
import {useLazyGetCountryQuery, useUpdateCountries} from "../../../../../api/dictionary";
import { MODAL_ADD_FORM as MODAL_ADD_FORM_CITIES, ModalAddParamsCities } from "../../pages/cities/add";
import { MODAL_ADD_FORM as MODAL_ADD_FORM_LOCATIONS, ModalAddParamsLocations } from "../../pages/locations/add";
import {useDispatch} from "react-redux";
import Paper from "@mui/material/Paper";

type OptionData = {
    label: string
    id: string
}

type Props = {
    defaultValue: OptionData
    formFieldName: MODAL_ADD_FORM_CITIES.COUNTRY | MODAL_ADD_FORM_LOCATIONS.COUNTRY
    setCurrentCountry(value: OptionData): void
}

const CountryInput = ({ defaultValue, formFieldName, setCurrentCountry } : Props) => {

  const { register, setValue, trigger, formState: { errors } } = useFormContext<ModalAddParamsCities | ModalAddParamsLocations>()

  const [ countryValue, setCountryValue ] = useState<OptionData>(defaultValue);

  const [ countriesRequest, { data, isLoading, isSuccess: isSuccessCountries } ] = useLazyGetCountryQuery()

  const debouncedRequest = useRef(debounce(countriesRequest, 1000)).current

  const [ userHasStartedTyping, setUserHasStartedTyping ] = useState(false);

  const dispatch = useDispatch()

  const countries = (data?.results ?? [])
    .map(({ name, code}: { code: string, name: string }) => ({ id: code, label: name }))

  const { t } = useTranslation()

  useEffect(() => {
    setCountryValue(defaultValue)
  }, [ defaultValue ])

  useEffect(() => {
    return () => {
      // @ts-ignore
      dispatch(useUpdateCountries());
    };
  }, []);

  const handleChange = (_: Event, value: OptionData, reason: string) => {
    setValue(formFieldName, reason !== 'clear' ? value?.label : '');
    setCurrentCountry(value);
    // @ts-ignore
    trigger(formFieldName)
  }

  return (
    <div id={formFieldName}>
      <Autocomplete
        options={countries}
        loading={isLoading}
        noOptionsText={t('calc__text__main__noOptionsFound') }
        value={countryValue}
        {...register(formFieldName, { required: true })}
        // @ts-ignore
        onChange={handleChange}
        isOptionEqualToValue={(o: OptionData, v: OptionData) => o.id === v.id}
        filterOptions={(options) => options}
        PaperComponent={({ children }) => {
          return (
            <Paper>
              {userHasStartedTyping && isSuccessCountries && children}
            </Paper>
          )
        }}
        loadingText={t('common__message__loadingText')}
        getOptionLabel={(option) => option.label}
        disableClearable
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={async ({ target: { value: search } }) => {
              if (search.length >= 3) {
                await debouncedRequest({search});
                setUserHasStartedTyping(true);
              }
            }}
            error={Boolean(errors[formFieldName])}
            variant='outlined'
            label={t('common__text__country')}
            disabled={isLoading}
            InputProps={{
              ...params.InputProps,
            }}
            InputLabelProps={{ shrink: true }}
          />)}/>
    </div>
  )
}

export default CountryInput
