import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistStore } from 'redux-persist'
import { calcApi, calcSlice } from 'api/calc'
import { deliveryRoutesApi } from 'api/delivery_routes'
import { containersApi } from 'api/containers'
import { tnvedApi, tnvedCodesSlice } from 'api/tnved'
import { geocodingApi } from 'api/geocoding'
import {authApi, authSlice} from 'api/auth'
import { informersApi } from 'api/informers'
import { deliveryTimeApi } from 'api/delivery_time'
import { palletesApi } from 'api/palletes'
import { locationsApi } from 'api/locations'
import { declarationTypesApi } from 'api/declaration_types'
import { certificationSchemesApi } from 'api/certification_schemes'
import { documentDurationsApi } from 'api/document_durations'

import { dictionaryApi, dictionarySlice } from 'api/dictionary'
import { mediaStoragesApi } from 'api/media_storage'
import {localizationApi} from "../api/localization";

// const persistConfig = {
//   key: 'root',
//   storage,
// }


export const rootReducer = combineReducers({
  [calcApi.reducerPath]: calcApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [deliveryRoutesApi.reducerPath]: deliveryRoutesApi.reducer,
  [containersApi.reducerPath]: containersApi.reducer,
  [geocodingApi.reducerPath]: geocodingApi.reducer,
  [tnvedApi.reducerPath]: tnvedApi.reducer,
  [informersApi.reducerPath]: informersApi.reducer,
  [deliveryTimeApi.reducerPath]: deliveryTimeApi.reducer,
  [palletesApi.reducerPath]: palletesApi.reducer,
  [locationsApi.reducerPath]: locationsApi.reducer,
  [dictionaryApi.reducerPath]: dictionaryApi.reducer,
  [mediaStoragesApi.reducerPath]: mediaStoragesApi.reducer,
  [declarationTypesApi.reducerPath]: declarationTypesApi.reducer,
  [certificationSchemesApi.reducerPath]: certificationSchemesApi.reducer,
  [documentDurationsApi.reducerPath]: documentDurationsApi.reducer,
  [localizationApi.reducerPath]: localizationApi.reducer,
  [calcSlice.name]: calcSlice.reducer,
  [tnvedCodesSlice.name]: tnvedCodesSlice.reducer,
  [dictionarySlice.name]: dictionarySlice.reducer,
  [authSlice.name]: authSlice.reducer,
})


// const persistedReducer = persistReducer(persistConfig, rootReducer)


export const store = configureStore({
  // reducer: persistedReducer,
  reducer: {
    [calcApi.reducerPath]: calcApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [deliveryRoutesApi.reducerPath]: deliveryRoutesApi.reducer,
    [containersApi.reducerPath]: containersApi.reducer,
    [tnvedApi.reducerPath]: tnvedApi.reducer,
    [geocodingApi.reducerPath]: geocodingApi.reducer,
    [informersApi.reducerPath]: informersApi.reducer,
    [deliveryTimeApi.reducerPath]: deliveryTimeApi.reducer,
    [palletesApi.reducerPath]: palletesApi.reducer,
    [locationsApi.reducerPath]: locationsApi.reducer,
    [dictionaryApi.reducerPath]: dictionaryApi.reducer,
    [mediaStoragesApi.reducerPath]: mediaStoragesApi.reducer,
    [declarationTypesApi.reducerPath]: declarationTypesApi.reducer,
    [certificationSchemesApi.reducerPath]: certificationSchemesApi.reducer,
    [documentDurationsApi.reducerPath]: documentDurationsApi.reducer,
    [localizationApi.reducerPath]: localizationApi.reducer,
    [calcSlice.name]: calcSlice.reducer,
    [dictionarySlice.name]: dictionarySlice.reducer,
    [authSlice.name]: authSlice.reducer,
    [tnvedCodesSlice.name]: tnvedCodesSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }).concat(
    calcApi.middleware,
    authApi.middleware,
    deliveryRoutesApi.middleware,
    containersApi.middleware,
    tnvedApi.middleware,
    geocodingApi.middleware,
    informersApi.middleware,
    deliveryTimeApi.middleware,
    palletesApi.middleware,
    declarationTypesApi.middleware,
    certificationSchemesApi.middleware,
    documentDurationsApi.middleware,
    locationsApi.middleware,
    dictionaryApi.middleware,
    mediaStoragesApi.middleware,
    localizationApi.middleware
  ),
})


export const persistor = persistStore(store)
