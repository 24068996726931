import React from "react";
import sortBy from "lodash/sortBy";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

import Button from "@mui/material/Button";
import Add from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import SummaryCalculator from '../../../../common/summaryCalculator'
import {
  CALC_ID,
  useCloneCalculationMutation,
  useInvoicesData,
  useOrder,
  useResetOrderId,
  useSummary,
  useSummaryCustoms,
  useCurrentRoute,
  useSummaryCompleted,
  useDeclarationsByOrderIdQuery,
} from "../../../../../api/calc";
import {ROUTES} from "../../../../../config/constants";
import {useGetCalcIdFromRoute} from "../../../../../utils/hooks";

import './right-panel.scss';

const RightPanel = () => {

  const order = useOrder();
  const invoices = useInvoicesData();
  const sortedInvoices = sortBy(invoices, 'id');
  const summaryOrder = useSummary();
  const summaryCustoms = useSummaryCustoms();
  const summaryDelivery = useCurrentRoute();
  const summaryCompleted = useSummaryCompleted()
  const orderId = useGetCalcIdFromRoute()

  const { data: declarations } = useDeclarationsByOrderIdQuery(orderId)

  const [ cloneOrder ] = useCloneCalculationMutation()

  const navigate = useNavigate();

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const openNewCalculation = () => {

    navigate(`/${ROUTES.CABINET}/${ROUTES.CALCULATOR}`, { replace: true })
    localStorage.removeItem(CALC_ID);
    // @ts-ignore
    dispatch(useResetOrderId(''));
  }

  const cloneCalculation = () => cloneOrder(orderId)?.then((data) => {
    // @ts-ignore
    const newNumber = data?.data?.num;

    if (newNumber) {
      navigate(`/${ROUTES.CABINET}/${ROUTES.CALCULATOR}/${newNumber}`);
    }}
  )

  return (
    <>
      <div className='right-panel-buttons'>
        <Button
          variant='outlined'
          sx={{ width: orderId ? '132px': '100%', marginBottom: 4, fontSize: 15, fontWeight: 500, letterSpacing: '0.46px' }}
          onClick={openNewCalculation}
          startIcon={<Add />}
          fullWidth={!orderId}
        >{t('calc__text__rightPanel__newCalculation')}</Button>
        {orderId && <Button
          variant='outlined'
          sx={{ width: '204px', marginBottom: 4, fontSize: 15, fontWeight: 500, letterSpacing: '0.46px' }}
          onClick={cloneCalculation}
          startIcon={<ContentCopyIcon />}
        >{t('calc__text__rightPanel__copyCalculation')}</Button>}
      </div>
      <div className='right-panel'>
        <SummaryCalculator
          order={order}
          invoices={sortedInvoices}
          summaryOrder={summaryOrder}
          summaryCustoms={summaryCustoms}
          summaryDelivery={summaryDelivery}
          summaryCompleted={summaryCompleted}
          declarations={declarations}
        />
      </div>
    </>
  )
}

export default RightPanel
