import React from "react";

import Typography from "@mui/material/Typography";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";

import Completed from "../../../../../../../../../../common/completed";

import './code-services.scss'
import {numberWithSpaces} from "../../../../../../../../../../../utils/helpers";

type Props = {
  code: CertificationCenterCode
}

const CodeServices = ({ code } : Props ) => {

  const getCompletedInfo = (documents: CertificationCenterCodeDocument[]) => documents.map((document: CertificationCenterCodeDocument) => (
    {
      label: document.document_name,
      value: `${numberWithSpaces(document.document_price)} USD`
    }
  ))

  return <Accordion sx={{
    marginBottom: 0,
    background: 'inherit',
    boxShadow: 'none',
    '&.Mui-expanded': {
      marginBottom: 0
    }
  }} className='code-services'>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon/>}
      sx={{
        margin: '0',
        minHeight: '60px',
        padding: '0px 28px 0 24px',
        '&.Mui-expanded': {
          minHeight: '60px',
          borderBottom: 'none'
        }
      }}
    >
      <div className='code-services__title'>
        <Typography variant='subtitle1' sx={{ fontWeight: 500 }}>
          {code.code}
        </Typography>
        <Typography variant='subtitle1' sx={{ fontWeight: 500 }}>
          {`${numberWithSpaces(code.subtotal)} USD`}
        </Typography>
      </div>
    </AccordionSummary>
    <AccordionDetails sx={{padding: '0 24px 24px 24px'}}>
      <Completed
        info={getCompletedInfo(code.documents)}
        isFullRow
      />
    </AccordionDetails>
  </Accordion>
}

export default CodeServices
