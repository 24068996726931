export const numberWithSpaces = (value?: number | string) => {
  if (!value) return '';
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export const compareBankCommissions = (a: BankCommissions, b: BankCommissions) => {
  if (!a.id || !b.id) {
    return -1;
  }

  return a.id > b.id ? 1 : -1;
}

export const formatHSCode = (code: string) => {
  return code.replace(/^(\d{4})(\d{2})(\d{3})(\d)$/, "$1 $2 $3 $4");
}
