import {useTranslation} from "react-i18next";
import {numberWithSpaces} from "../../../../../utils/helpers";

export const getCertificateInfo = (summaryCustoms?: DeclarationSummaryParamsOutput | Record<string, never>) => {

  const { t } = useTranslation();

  return [
    [
      ...(summaryCustoms?.cert_total_manual || summaryCustoms?.cert_total
        ? [
          {
            label: t('dictionaries__text__certification__priceManual'),
            value: `${numberWithSpaces(summaryCustoms?.cert_total_manual || summaryCustoms?.cert_total)} USD`,
          },
        ]
        : []),
    ],
  ]
}
