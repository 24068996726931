import Typography from "@mui/material/Typography";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Summary from '../summary';

import COLORS from 'styles/colors.scss'
import {useTranslation} from "react-i18next";

import './calculation.scss'
import React, {useRef} from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import PrintOrder from "../../../../print/order";
import {useReactToPrint} from "react-to-print";
import {
  useDeleteOrderMutation, useCloneCalculationMutation
} from "../../../../../api/calc";
import AlertBottom from "../../../../common/alertBottom";
import {getFlag} from "../../../calculator/components/delivery/helpers";
import cx from "classnames";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import {ROUTES} from "../../../../../config/constants";
import {getPluralForm} from "../../../../../utils/validation";
import {numberWithSpaces} from "../../../../../utils/helpers";
import BigValueCalculation from "./components/bigValue";

type Props = {
  order: Calculation;
  refetch(): void;
}

export const enum CalculationStatus {
  DRAFT = 'draft',
  DONE = 'done',
}

const Calculation = ({order, refetch}: Props) => {

  // @ts-ignore
  const isCompleted = order.status === CalculationStatus.DONE;

  const [ isExpanded, setIsExpanded ] = React.useState(false);
  const [ openAlert, setOpenAlert ] = React.useState(false);

  const { t } = useTranslation()

  const navigate = useNavigate()

  const [ deleteOrder ] = useDeleteOrderMutation()
  const [ cloneOrder ] = useCloneCalculationMutation()

  const componentRef = useRef();

  const handleRemove = () => {
    deleteOrder(order.num).then(() => {
      setOpenAlert(true);
      refetch()
    })
  }

  const getPageMargins = () => `@page { margin: 32px 48px !important; }`

  const handlePrint = useReactToPrint({
    // @ts-ignore
    content: () => componentRef.current,
    documentTitle: `${t('computation__text__computation')} №${order.num}`
  });

  const routeFinalDate = moment(order?.delivery_route?.expiration_date).format('YYYY-MM-DD');
  const currentDate = moment().startOf('day');
  const isActual = moment(routeFinalDate).isSameOrAfter(currentDate);
  const routeFinalDateToShow = moment(order?.delivery_route?.expiration_date).format('DD.MM.YYYY');

  const cloneCalculation = () => cloneOrder(order.num)?.then((data) => {
    // @ts-ignore
    const newNumber = data?.data?.num;

    if (newNumber) {
      navigate(`/${ROUTES.CABINET}/${ROUTES.CALCULATOR}/${newNumber}`);
    }}
  )

  const getOrderName = (orderName: string) => {
    if (!isExpanded) {
      return orderName.length > 50 ? orderName.substring(0, 50) + "..." : orderName
    }

    return orderName;

  }

  const locationFrom = order?.delivery_route?.location_from;
  const locationTo = order?.delivery_route?.location_to;

  const PRODUCTS_DECLENSIONS: PluralForms = [ t('declension__text__itemOne'), t('declension__text__itemSeveral'), t('declension__text__itemMany') ];
  const CODES_DECLENSIONS: PluralForms = [ t('declension__text__HSCodeOne'), t('declension__text__HSCodeSeveral'), t('declension__text__HSCodeMany') ];

  const productCount = order?.customs?.product_count;
  const codeCount = order?.customs?.code_count;

  return (
    <div className='calculation'>
      <Typography variant='body2' className='calculation__countries'>
        {(locationFrom && locationTo) && <div className='calculation__flag'>
          {getFlag({code: locationFrom.country.code, height: '14px', marginRight: '4px'})}
          {`${locationFrom.name} (${locationFrom.country.name})`}&nbsp;→&nbsp;
          {getFlag({
            code: locationTo.country.code,
            height: '14px',
            marginRight: '4px'
          })}
          {`${locationTo.name} (${locationTo.country.name})`}
        </div>}
        <span className={cx('calculation__route', {
          'calculation__route_isActual': isActual,
        })}>
          {isActual ? `${t('calculations__text__routeIsActualBefore')} ${routeFinalDateToShow}` : t('calculations__text__routeIsNotActual')}
        </span>
      </Typography>
      <Accordion
        expanded={isExpanded}
        onChange={() => setIsExpanded(!isExpanded)}
        sx={{
          boxShadow: 'none',
          marginBottom: 0,
          '&.Mui-expanded': {
            marginBottom: '0',
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          sx={{
            padding: '20px 24px 14px',
            alignItems: 'start',
            '.MuiAccordionSummary-content': {
              margin: '8px 0'
            },
            '&.Mui-expanded': {
              borderBottom: 'none',
            },
            '.Mui-expanded.MuiAccordionSummary-content': {
              margin: '8px 0'
            },
            '.MuiAccordionSummary-expandIconWrapper': {
              svg: {
                width: '32px',
                height: '32px',
              }
            }
          }}
        >
          <div className='calculation__accordion'>
            {isCompleted ? <CheckCircleIcon sx={{ color: COLORS.GREEN, marginRight: 1, width: '24px', height: '24px'}} /> : <CheckCircleOutlineIcon sx={{ color: COLORS.SLIGHTLY_GRAY, marginRight: 1, width: '24px', height: '24px' }} />}
            <div className='calculation__content'>
              <div className='calculation__content-left'>
                <Typography
                  variant='h6'
                  sx={{fontWeight: 500, lineHeight: 1}}
                >{getOrderName(order.order_name)}</Typography>
                <Typography variant='body2' sx={{ marginTop: 1 }}>
                  {`${t('common__text__invoice')} №${order.num} ${t('common__text__from')} ${moment(order.created_at).format('DD.MM.YYYY, hh:mm')} MSK`}
                </Typography>
              </div>
              <div className='calculation__content-right'>
                <Typography variant='h6'
                  sx={{lineHeight: 1}}>{order.summary?.total_amount ? `${numberWithSpaces(order.summary?.total_amount)} USD` : ''}
                </Typography>
                <Typography variant='body2' sx={{ marginTop: 1 }}>
                  {productCount ? `${productCount} ${getPluralForm(productCount, PRODUCTS_DECLENSIONS)}, ` : ''}
                  {codeCount ? `${codeCount} ${getPluralForm(codeCount, CODES_DECLENSIONS)}` : ''}
                </Typography>
              </div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '0 24px 24px' }}>
          <div className='calculation__details'>
            {isExpanded && <Summary orderId={order.num} noLimeColor/>}
          </div>
        </AccordionDetails>
      </Accordion>
      <div className='calculation__bottom'>
        <div className='calculation__bottom-actions'>
          <Button
            variant='outlined'
            sx={{ marginRight: 3, minWidth: 140 }}
            onClick={() => navigate(`/cabinet/calculator/${order.num}`)}
          >
            {t('actions__text__edit')}
          </Button>
          <Button
            variant='outlined'
            sx={{ marginRight: 3, minWidth: 140 }}
            onClick={cloneCalculation}
          >
            {t('calc__text__rightPanel__copyCalculation')}
          </Button>
          <Button
            variant='outlined'
            sx={{ marginRight: 3, minWidth: 140 }}
            onClick={handleRemove}
          >
            {t('actions__text__delete')}
          </Button>
          {isCompleted && <>
            <Button
              variant='outlined'
              onClick={handlePrint}
              sx={{ minWidth: 140 }}
            >
              {t('actions__text__print')}
              <div className='calculation__print-version'>
                <PrintOrder ref={componentRef} />
                <style>{getPageMargins()}</style>
              </div>
            </Button>
          </>}
        </div>
        <Button
          sx={{ marginTop: 3 }}
          variant='contained'
          startIcon={<SendIcon />}
          fullWidth
        >
          {t('actions__message__changeCalculationToTheApplication')}
        </Button>
      </div>
      <AlertBottom
        open={openAlert}
        onClose={() => setOpenAlert(false)}
        message={t('actions__message__removeCalculation')}
      />
    </div>
  )
}

export default Calculation
