import React from "react";

import Typography from '@mui/material/Typography';
import {useTranslation} from "react-i18next";
import {COLORS} from "../../../../../config/constants";
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import CheckIcon from '@mui/icons-material/Check';

type Props = {
    anchorEl: HTMLElement | null
    handleCloseMenu(): void
}

const CurrencyMenu = ({ anchorEl, handleCloseMenu } : Props) => {

  const { t } = useTranslation()

  return (
    <Menu
      id='menu-language'
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}
      sx={{
        '.MuiMenuItem-root': {
          minHeight: 30
        },
        '.MuiList-root': {
          width: 320,
        },
      }}
    >
      <div className='app-bar__topContainer'>
        <Typography variant='body1' sx={{fontWeight: 500, color: COLORS.BLACK_DARK}}>
          {t('settings__text__currencies')}
        </Typography>
      </div>
      <Divider sx={{marginBottom: 0, marginTop: '1px'}}/>
      <div className='app-bar__menuContainer'>
        <MenuItem sx={{padding: '8px 16px'}} onClick={handleCloseMenu}>
          <CheckIcon sx={{color: 'rgba(0, 0, 0, 0.54)'}}/>
          <Typography variant='body2' sx={{marginLeft: 4, color: COLORS.BLACK_DARK, fontWeight: 500}}>
            USD&nbsp;
          </Typography>
          <Typography variant='body2' sx={{color: COLORS.BLACK_DARK}}>
            Американский доллар
          </Typography>
        </MenuItem>
        <MenuItem sx={{padding: '8px 16px'}} onClick={handleCloseMenu}>
          <Typography variant='body2' sx={{marginLeft: 7, color: COLORS.BLACK_DARK, fontWeight: 500}}>
            RUB&nbsp;
          </Typography>
          <Typography variant='body2' sx={{color: COLORS.BLACK_DARK}}>
            Российский рубль
          </Typography>
        </MenuItem>
        <MenuItem sx={{padding: '8px 16px'}} onClick={handleCloseMenu}>
          <Typography variant='body2' sx={{marginLeft: 7, color: COLORS.BLACK_DARK, fontWeight: 500}}>
            CNY&nbsp;
          </Typography>
          <Typography variant='body2' sx={{color: COLORS.BLACK_DARK}}>
            Китайский юань
          </Typography>
        </MenuItem>
      </div>
      <Divider sx={{marginBottom: 0, marginTop: 0}}/>
      <div className='app-bar__currenciesListContainer'>
        <MenuItem sx={{padding: '8px 16px'}}>
          <Typography variant='body2' sx={{marginLeft: 7, color: COLORS.BLACK_DARK}}>
              1 USD = 101,23 RUB
          </Typography>
        </MenuItem>
        <MenuItem sx={{padding: '8px 16px'}}>
          <Typography variant='body2' sx={{marginLeft: 7, color: COLORS.BLACK_DARK}}>
              1 USD = 7,25 CNY
          </Typography>
        </MenuItem>
      </div>
      <Divider sx={{marginBottom: 0, marginTop: 0}}/>
      <div className='app-bar__bottomContainer'>
        <Typography variant='overline'
          className='app-bar__bottomButton'
          onClick={handleCloseMenu}
          sx={{
            margin: '0 0 0 auto',
            fontSize: '13px',
            fontWeight: 500,
            color: COLORS.BLACK_DARK,
          }}>
          {t('settings__text__allCurrencies')}
        </Typography>
      </div>
    </Menu>
  )
}

export default CurrencyMenu
