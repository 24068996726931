import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import { useForm } from "react-hook-form";
import 'react-phone-input-2/lib/material.css'
import get from "lodash/get";
import { useNavigate } from "react-router-dom";

import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';

import {
  useCreateOrderMutation,
  useGetOrder, useSetCompletedByOrderIdMutation, useSummaryCompleted,
  useUpdateOrderMutation,
} from 'api/calc'

import Notification, {NOTIFICATION_TYPE} from '../../../../../../common/notification'
import {useUser} from "../../../../../../../api/auth";

import './editMainInfo.scss'
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {SORTING_OPTIONS} from "../../../../../calculations";

export enum ORDER_FORM {
  ORDER_NAME = 'order_name',
  COMMENT = 'comment',
}

export type OrderParams = {
  [ORDER_FORM.ORDER_NAME]: string
  [ORDER_FORM.COMMENT]: string
}

type Props = {
    onSend(): void
    hasCancelButton?: boolean
    onClose?(): void
}

export const enum CURRENCIES_OPTIONS {
  RUB = 'rub',
  USD = 'usd',
  CYN = 'cyn',
}


const EditMainInfo = ({ onSend, hasCancelButton, onClose } : Props) => {

  const { t } = useTranslation()

  const navigate = useNavigate()

  const { data: order } = useGetOrder()

  const user = useUser()

  const [ showErrorNotification, setShowErrorNotification ] = useState<boolean | undefined>(false);

  const [ createOrder ] = useCreateOrderMutation()

  const [ updateOrder ] = useUpdateOrderMutation()

  const [ setCompleted ] = useSetCompletedByOrderIdMutation()

  const completedInfo = useSummaryCompleted();

  const defaultValues = {
    [ORDER_FORM.ORDER_NAME]: order?.[ORDER_FORM.ORDER_NAME],
    [ORDER_FORM.COMMENT]: order?.[ORDER_FORM.COMMENT],
  };

  const { register, handleSubmit, getValues, setValue, reset, clearErrors, setError, formState: { errors, isValid } } = useForm<OrderParams>({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  useEffect(() => {
    if (order?.num) {
      reset(defaultValues)
    }
  }, [ order?.num ])


  const onSubmit = handleSubmit(async values => {

    if (order?.num) {
      const result = await updateOrder({ id: order?.num, ...values})
      const id = get(result, 'data.num', '')
      if (!id) {
        setShowErrorNotification(true);
        return
      }
      onSend();
      setShowErrorNotification(false);
      return
    }

    const result = await createOrder(values)
    const id = get(result, 'data.num', '')
    if (!id) {
      setShowErrorNotification(true);
      return
    }
    onSend()

    setCompleted({
      orderId: id,
      ...completedInfo,
      common: true,
    })

    setShowErrorNotification(false);
    if (id) navigate(`${id}` )

  })

  return (
    <>
      <form
        id='main'
        className='main-form'
        onSubmit={onSubmit}
      >
        <TextField
          {...register(ORDER_FORM.ORDER_NAME, { required: t('common__text__isRequired'), maxLength: 200 })}
          fullWidth
          error={Boolean(errors[ORDER_FORM.ORDER_NAME])}
          helperText={errors[ORDER_FORM.ORDER_NAME]?.message}
          variant='outlined'
          label={t('calc__text__main__nameOfOrder')}
          InputLabelProps={{ shrink: true }}
          id={ORDER_FORM.ORDER_NAME}
        />
        <TextField
          {...register(ORDER_FORM.COMMENT)}
          fullWidth
          variant='outlined'
          label={t('calc__text__main__comment')}
          InputLabelProps={{ shrink: true }}
          id={ORDER_FORM.ORDER_NAME}
          multiline
          rows={2}
        />
        <FormControl sx={{ width: '320px', boxSizing: 'border-box' }}>
          <InputLabel id='sorting-select'>{t('common__text__currency')}</InputLabel>
          <Select
            labelId='select-currency'
            id='select-currency'
            value={CURRENCIES_OPTIONS.RUB}
            label={t('common__text__currency')}
            onChange={({ target: { value } }) => console.log(value)}>
            <MenuItem value={CURRENCIES_OPTIONS.RUB}>RUB — Российский рубль</MenuItem>
            <MenuItem value={CURRENCIES_OPTIONS.USD}>USD — Американский доллар</MenuItem>
            <MenuItem value={CURRENCIES_OPTIONS.CYN}>CYN — Китайский юань</MenuItem>
          </Select>
        </FormControl>
        <div className='main-form__buttons'>
          {hasCancelButton && <Button
            variant='outlined'
            sx={{ minWidth: '212px', marginRight: 3}}
            onClick={onClose}
          >{t('actions__text__cancel')}</Button>}
          <Button
            variant='contained'
            fullWidth={!hasCancelButton}
            type='submit'
            startIcon={<CheckIcon />}
            disabled={!isValid}
            sx={{ minWidth: hasCancelButton ? '452px': 'auto'}}
          >{t('actions__text__saveTheDeliveryData')}</Button>
        </div>
        {showErrorNotification && <Notification type={NOTIFICATION_TYPE.ERROR} title={t('errors__message__save')} text={t('errors__message__tryLater5Min')} sx={{ marginTop: 4 }} />}
      </form>
    </>
  )
}

export default EditMainInfo
