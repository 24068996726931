import {numberWithSpaces} from "../../../../../utils/helpers";

export const getAdditionalInfo = (extraServices?: ExtraService[]) => {
  if (!extraServices) {
    return []
  }

  const servicesArray = extraServices.flatMap(extraService =>
    extraService.services.map(service => ({
      label: service.name,
      value: `${numberWithSpaces(service.price)} ${service.currency}`
    })));

  return [ servicesArray ]
}
