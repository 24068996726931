import React from "react";

import Typography from '@mui/material/Typography';
import {useTranslation} from "react-i18next";
import {COLORS, ROUTES} from "../../../../../config/constants";
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleFilledIcon from '@mui/icons-material/AccountCircle';

import Divider from '@mui/material/Divider';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import {useNavigate} from "react-router-dom";

import {useDispatch} from "react-redux";
import {useResetOrderId} from "../../../../../api/calc";
import {removeToken, useUser} from "../../../../../api/auth";
import {useGetCalcIdFromRoute} from "../../../../../utils/hooks";
import {useAuthContext} from "../../../../../context/AuthContext";
import CheckIcon from "@mui/icons-material/Check";

type Props = {
    anchorEl: HTMLElement | null
    handleCloseMenu(): void
}

const UserMenu = ({ anchorEl, handleCloseMenu } : Props) => {

  const { t } = useTranslation()

  const navigate = useNavigate();

  const orderId = useGetCalcIdFromRoute()

  const { setIsSuccess, setUser } = useAuthContext();

  const dispatch = useDispatch()

  const handleGoToSettings = () => {
    handleCloseMenu();
    navigate(`/${ROUTES.CABINET}/${ROUTES.SETTINGS}`);
  };

  const handleClickExit = () => {
    if (orderId) {
      // @ts-ignore
      dispatch(useResetOrderId(orderId));
    }
    removeToken();
    setUser(undefined);
    navigate(ROUTES.LOGIN);
  }

  const user = useUser()

  return (
    <Menu
      id='menu-appbar'
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}
      sx={{
        '.MuiMenuItem-root': {
          minHeight: 36
        },
        '.MuiList-root': {
          width: 360,
        },
      }}
    >
      <div className='app-bar__topContainer'>
        <Typography variant='body1' sx={{fontWeight: 500, color: COLORS.BLACK_DARK}}>
          {user?.first_name} {user?.last_name}
        </Typography>
        <Typography variant='body2'>
          {user?.email}
        </Typography>
      </div>
      <Divider sx={{marginBottom: 0, marginTop: '1px'}}/>
      <div className='app-bar__menuContainer'>
        <MenuItem sx={{padding: '8px 16px 4px'}} onClick={handleGoToSettings}>
          <SettingsIcon sx={{color: 'rgba(0, 0, 0, 0.54)'}}/>
          <Typography variant='body2' sx={{marginLeft: 4, color: COLORS.BLACK}}>
            {t('menu__text__settings')}
          </Typography>
        </MenuItem>
        <MenuItem sx={{padding: '8px 16px 4px'}} onClick={handleGoToSettings}>
          <AccountCircleFilledIcon sx={{color: 'rgba(0, 0, 0, 0.54)'}}/>
          <Typography variant='body2' sx={{marginLeft: 4, color: COLORS.BLACK}}>
            {t('common__text__profile')}
          </Typography>
        </MenuItem>
        <MenuItem sx={{padding: '8px 16px 4px'}} onClick={handleCloseMenu}>
          <CreditCardIcon sx={{color: 'rgba(0, 0, 0, 0.54)'}}/>
          <Typography variant='body2' sx={{marginLeft: 4, color: COLORS.BLACK}}>
            {t('common__text__paymentInfo')}
          </Typography>
        </MenuItem>
      </div>
      <Divider sx={{marginBottom: 0, marginTop: 0}}/>
      <div className='app-bar__bottomContainer'>
        <Typography variant='overline'
          className='app-bar__bottomButton'
          onClick={handleClickExit}
          sx={{
            margin: '0 0 0 auto',
            fontSize: '13px',
            fontWeight: 500,
            color: COLORS.BLACK_DARK,
            textAlign: 'right',
          }}>
          {t('common__text__logOut')}
        </Typography>
      </div>
    </Menu>
  )
}

export default UserMenu
