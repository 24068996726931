import {ReactNode, useEffect, useState} from 'react'

import Typography from "@mui/material/Typography";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import COLORS from 'styles/colors.scss'
import './template.scss'
import cx from "classnames";
import * as React from "react";

type TemplateParams = {
    text: string
    name?: string
    children: ReactNode
    isCompleted?: boolean
    inProgress?: boolean
    isDisabled?: boolean
    isExpanded?: boolean
    id: string
}

const Template = (props: TemplateParams) => {

  const shouldShowProgressIcon = (!props.isDisabled || props.inProgress) && !props.isCompleted;

  const [ isExpandedByDefault, setIsExpandedByDefault ] = useState(props.isExpanded);

  useEffect(() => {
    setIsExpandedByDefault(props.isExpanded)
  }, [ props.isExpanded ])

  const handleChange = (event: React.SyntheticEvent, expanded: boolean) => setIsExpandedByDefault(expanded)

  return (
    <Accordion
      disabled={props.isDisabled}
      expanded={isExpandedByDefault}
      onChange={handleChange}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ width: '32px', height: '32px' }}/>}
        aria-controls={`step-${props.id}`}
        id={`step-${props.id}`}
        sx={{
          padding: '12px 24px',
          '&.Mui-disabled': {
            padding: '18px 56px 13px 24px',
          }
        }}
      >
        <div className='title-container'>
          <Typography
            variant='h5'
            className={cx('text', {
              'text_disabled': props.isDisabled,
            })}
            sx={{ fontWeight: 700 }}
          >
            {props.isCompleted && <CheckCircleIcon sx={{ color: COLORS.GREEN, marginRight: 1, width: '32px', height: '32px'}} />}
            {shouldShowProgressIcon && <CheckCircleOutlineIcon sx={{ color: COLORS.SLIGHTLY_GRAY, marginRight: 1, width: '32px', height: '32px' }} />}
            <span className='text__title'></span>
            {props.name}
          </Typography>
          {<Typography variant='h6' className='title-container__name'>
            {props.text}
          </Typography>}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {props.children}
      </AccordionDetails>
    </Accordion>
  )
}

export default Template
