import TextField from '@mui/material/TextField'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from "react-i18next"
import { InvoiceForm, INVOICE_FORM } from '../../editInvoice'
import {ADORNMENT_LOCATION, INFORMERS_CODES} from "../../../../../../../../../../../../config/constants";
import {InputAdornment} from "@mui/material";
import Tooltip from "../../../../../../../../../../../common/tooltip";
import 'mapbox-gl/dist/mapbox-gl.css';
import {useLazyGetCoordsQuery} from "../../../../../../../../../../../../api/geocoding";
import './locationInput.scss'
import Map from '../locationMap'
import React, {useEffect, useRef, useState} from "react";
import {useInformers} from "../../../../../../../../../../../../api/informers";
import Autocomplete from "@mui/material/Autocomplete";
import debounce from "lodash/debounce";
import {useLazyGetLocationsQuery} from "../../../../../../../../../../../../api/locations";
import Paper from "@mui/material/Paper";

type OptionData = {
  label: string
}

type LocationItemDefaultCity = LocationItem & OptionData;

type Props = {
  defaultCity?: LocationItemDefaultCity
}

const LocationInput  = ({defaultCity} : Props) => {

  const { register, setError, setValue, clearErrors, formState: { errors } } = useFormContext<InvoiceForm>()

  const [ geocodingRequest, { data, isUninitialized, isSuccess } ] = useLazyGetCoordsQuery()

  const [ locationRequest, { data: dataLocations, isLoading: isLoadingCities, isSuccess: isSuccessCities } ] = useLazyGetLocationsQuery()

  const debouncedRequest = useRef(debounce(locationRequest, 1000)).current

  const locations = (dataLocations || [])
    .map(item => ({ ...item, label: `${item.name}, ${item.country.name}` }))

  const informers = useInformers();

  const [ defaultValue ] = useState<LocationItemDefaultCity | undefined>(defaultCity)

  const [ userHasStartedTyping, setUserHasStartedTyping ] = useState(false);

  const { t } = useTranslation()

  useEffect(() => {
    if (defaultCity) {
      geocodingRequest(defaultCity.label)
    }
  }, [])

  useEffect(() => {
    if (!isUninitialized && isSuccess) {
      !data?.features[0] ? setError(INVOICE_FORM.LOCATION_CITY, { type: "custom",  message: t('errors__message__incorrectValue') }) : clearErrors(INVOICE_FORM.LOCATION_CITY)
    }
  }, [ data ])

  const handleSetAutocomplete = async (value: any) => {
    if (!value){
      return
    }
    await geocodingRequest(value.label)
    setValue(INVOICE_FORM.LOCATION_CITY, value.id)
  }

  return (
    <>
      <Autocomplete
        className={`invoice-form__${INVOICE_FORM.LOCATION_CITY}`}
        options={locations}
        loading={isLoadingCities}
        noOptionsText={t('calc__text__main__noOptionsFound') }
        {...register(INVOICE_FORM.LOCATION_CITY, { required: t('common__text__isRequired') })}
        defaultValue={defaultValue}
        filterOptions={(options) => options}
        PaperComponent={({ children }) => {
          return (
            <Paper>
              {userHasStartedTyping && isSuccessCities && children}
            </Paper>
          )
        }}
        // @ts-ignore
        onChange={(_, value ) => handleSetAutocomplete(value)}
        isOptionEqualToValue={(o: LocationItemDefaultCity, v: LocationItemDefaultCity) => o.id === v.id}
        loadingText={t('common__message__loadingText')}
        renderInput={(params) => (
          <TextField
            {...params}
            error={Boolean(errors[INVOICE_FORM.LOCATION_CITY])}
            helperText={errors[INVOICE_FORM.LOCATION_CITY]?.message}
            variant='outlined'
            label={t('calc__text__main__cityOfDeliveryExplanation')}
            InputProps={{
              ...params.InputProps,
              startAdornment: ADORNMENT_LOCATION,
              endAdornment: <InputAdornment position='end' sx={{ right: '20px', position: 'absolute'}}><Tooltip content={informers?.[INFORMERS_CODES.DELIVERY_CITY]} /></InputAdornment>
            }}
            onChange={ async ({ target: { value: query } }) => {
              if (query.length >= 3) {
                await debouncedRequest({q: query, loc_type: 'city'});
                setUserHasStartedTyping(true);
              }
            }}
            InputLabelProps={{ shrink: true }}
          />)}/>

      {data?.features[0].relevance > 0.75 && <Map lng={data?.features[0].center[0]} lat={data?.features[0].center[1]} />}
    </>

  )
}



export default LocationInput
