import React from "react";

import Typography from "@mui/material/Typography";

import {useTranslation} from "react-i18next";
import {getPluralForm} from "../../../../../../../utils/validation";
import IconButton from "@mui/material/IconButton";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import './main_info.scss';
import cx from "classnames";
import {numberWithSpaces} from "../../../../../../../utils/helpers";

type Props = {
    index: number
    isOpen?: boolean
    handleClickArrow?(): void
    showArrow?: boolean
    isCompleted: boolean
    count: number
    feesTotal: number
}

const MainInfo = ({ isOpen, handleClickArrow, showArrow, isCompleted, index, count, feesTotal } : Props) => {

  const { t } = useTranslation()

  const PRODUCTS_DECLENSIONS: PluralForms = [ t('declension__text__itemOne'), t('declension__text__itemSeveral'), t('declension__text__itemMany') ];

  if (isCompleted) {
    return <div className={cx({
      'customs__row': true,
      'customs__row-open': isOpen,
    })}>
      <div>
        <Typography variant='caption' className='customs__caption'>{t('calc__text__customs__customsDeclaration')}-{index + 1}</Typography>
        <Typography variant='subtitle1' className='customs__name'><b>{`${count} ${getPluralForm(count, PRODUCTS_DECLENSIONS)}`}</b></Typography>
      </div>
      <div>
        <Typography variant='caption' className='customs__caption'>{t('calc__text__customs__customsPaymentOnDeclaration')}</Typography>
        <Typography variant='subtitle1' className='customs__name'><b>{numberWithSpaces(feesTotal)} USD</b></Typography>
      </div>
      {showArrow && <IconButton onClick={handleClickArrow}>
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </IconButton>}
    </div>
  }

  return <Typography variant='h6' sx={{marginBottom: 3}}>{t('calc__text__customs__customsDeclaration')}-1</Typography>
}

export default MainInfo
