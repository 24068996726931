import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import {Controller, useFormContext} from "react-hook-form";

import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import {useLazyGetCertificationSchemesQuery} from "../../../../../../../../../api/certification_schemes";

import {CERTIFICATION_AUTO_FORM, CertificationFormType} from "../../index";

import {useLazyGetDeclarationTypesQuery} from "../../../../../../../../../api/declaration_types";
import {useLazyGetDocumentDurationsQuery} from "../../../../../../../../../api/document_durations";
import {useLazyGetTnvedQuery, useTnvedCodes} from "../../../../../../../../../api/tnved";

import './certification-code.scss';
import Divider from "@mui/material/Divider";
import {formatHSCode} from "../../../../../../../../../utils/helpers";

type Props = {
  index: number
  code: Declaration
}

type Option = {
  value: string,
  label: string
}

type CertificationSchemeOption = {
  code: string
  name: string
}

const CertificationCode = ({ index, code }: Props) => {

  const { t } = useTranslation()

  useEffect(() => {
    certificationSchemesRequest('');
    declarationTypesRequest('');
    documentDurationsRequest('');
  }, [ ])

  const [ certificationSchemesRequest, { data: certificationSchemesData } ] = useLazyGetCertificationSchemesQuery()
  const [ declarationTypesRequest, { data: declarationTypesData } ] = useLazyGetDeclarationTypesQuery()
  const [ documentDurationsRequest, { data: documentDurationsData }  ] = useLazyGetDocumentDurationsQuery()
  const [ tnvedRequest ] = useLazyGetTnvedQuery()

  const optionsCertificationSchemes = certificationSchemesData?.map((item: CertificationSchemeOption) => ({
    value: item.code,
    label: item.name,
  }));

  const optionsDeclarationTypes = declarationTypesData?.map((item: CertificationSchemeOption) => ({
    value: item.code,
    label: item.name,
  }));

  const optionsDocumentDurations = documentDurationsData?.map((item: CertificationSchemeOption) => ({
    value: item.code,
    label: item.name,
  }));

  const {  control, getValues, register, setValue, clearErrors, trigger } = useFormContext<CertificationFormType>()

  const hasCertificate = getValues()?.codes?.[index]?.[CERTIFICATION_AUTO_FORM.HAS_CERTIFICATE] || false;
  const hasCodeCompliance = getValues()?.codes?.[index]?.[CERTIFICATION_AUTO_FORM.HAS_CODE_COMPLIANCE] || false;
  const hasDeclarationCompliance = getValues()?.codes?.[index]?.[CERTIFICATION_AUTO_FORM.HAS_DECLARATION_COMPLIANCE] || false;
  const hasCertificateStateRegistration = getValues()?.codes?.[index]?.[CERTIFICATION_AUTO_FORM.HAS_CERTIFICATE_STATE_REGISTRATION] || false;

  const codes = useTnvedCodes();

  useEffect(() => {
    if (!codes.length) {
      tnvedRequest(code.code);
    }
  }, [ codes ])

  const foundCodeObject = codes.find((obj) => Object.prototype.hasOwnProperty.call(obj, code.code));

  const handleChangeSetCertificate = (event: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    setValue(`codes.${index}.${CERTIFICATION_AUTO_FORM.HAS_CERTIFICATE}`, event.target.checked);
    // @ts-ignore
    setValue(`codes.${index}.${CERTIFICATION_AUTO_FORM.DECLARATION_TYPE}`, '');
    // @ts-ignore
    setValue(`codes.${index}.${CERTIFICATION_AUTO_FORM.CERTIFICATION_SCHEME}`, '');
    // @ts-ignore
    setValue(`codes.${index}.${CERTIFICATION_AUTO_FORM.DOCUMENT_DURATION}`, '');
    // @ts-ignore
    setValue(`codes.${index}.${CERTIFICATION_AUTO_FORM.HAS_CODE_COMPLIANCE}`, '');
    // @ts-ignore
    setValue(`codes.${index}.${CERTIFICATION_AUTO_FORM.HAS_DECLARATION_COMPLIANCE}`, '');
    // @ts-ignore
    setValue(`codes.${index}.${CERTIFICATION_AUTO_FORM.HAS_CERTIFICATE_STATE_REGISTRATION}`, '');
    trigger();
  }

  const handleChangeCertificationScheme = (value: string) => {
    // @ts-ignore
    setValue(`codes.${index}.${CERTIFICATION_AUTO_FORM.CERTIFICATION_SCHEME}`, value);
    trigger();
  }

  const handleChangeDocumentDuration = (value: string) => {
    // @ts-ignore
    setValue(`codes.${index}.${CERTIFICATION_AUTO_FORM.DOCUMENT_DURATION}`, value);
    trigger();
  }

  const handleChangeDeclarationType = (value: string) => {
    // @ts-ignore
    setValue(`codes.${index}.${CERTIFICATION_AUTO_FORM.DECLARATION_TYPE}`, value);
    trigger();
  }

  const handleChangeSetCertificateCompliance = (event: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    setValue(`codes.${index}.${CERTIFICATION_AUTO_FORM.HAS_CODE_COMPLIANCE}`, event.target.checked);
    trigger();
  }

  const handleChangeSetDeclarationCompliance = (event: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    setValue(`codes.${index}.${CERTIFICATION_AUTO_FORM.HAS_DECLARATION_COMPLIANCE}`, event.target.checked);
    trigger();
  }

  const handleChangeSetHasCertificateStateRegistration = (event: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    setValue(`codes.${index}.${CERTIFICATION_AUTO_FORM.HAS_CERTIFICATE_STATE_REGISTRATION}`, event.target.checked);
    trigger();
  }

  return <div className={cx('certification-code', {
    'certification-code_hasCertificate': hasCertificate,
  })}>
    <Typography variant='subtitle1' gutterBottom sx={{ marginBottom: '4px' }}>
      {index + 1}. {t('common__text__codeOfHS')} {formatHSCode(code.code)} ({code.country_data.code})
    </Typography>
    <Typography variant='body2' gutterBottom sx={{ padding: '0 0 0 20px' }}>
      {foundCodeObject?.[code.code]?.GoodInfo.Name}
    </Typography>
    <Controller
      render={() => (
        <FormControlLabel
          {...register(`codes.${index}.${CERTIFICATION_AUTO_FORM.HAS_CERTIFICATE}`)}
          control={<Checkbox checked={hasCertificate}/>}
          label={t('dictionaries__text__certification__thereIsAValidCertificate')}
          // @ts-ignore
          onChange={handleChangeSetCertificate}
          sx={{ margin: '8px 0 0 -4px' }}
          className='certification-code__label'
        />
      )}
      name={`codes.${index}.${CERTIFICATION_AUTO_FORM.HAS_CERTIFICATE}`}
      control={control}
    />
    {!hasCertificate && (
      <>
        <Divider sx={{marginBottom: '6px', marginTop: '14px' }}/>
        <Controller
          render={() => (
            <FormControlLabel
              {...register(`codes.${index}.${CERTIFICATION_AUTO_FORM.HAS_CODE_COMPLIANCE}`)}
              control={<Checkbox checked={hasCodeCompliance}/>}
              label={t('dictionaries__text__certification__certificateCompliance')}
              // @ts-ignore
              onChange={handleChangeSetCertificateCompliance}
              sx={{ margin: '8px 0 0 -4px' }}
              className='certification-code__label'
            />
          )}
          name={`codes.${index}.${CERTIFICATION_AUTO_FORM.HAS_CODE_COMPLIANCE}`}
          control={control}
        />
        <br/>
        <Controller
          render={() => (
            <FormControlLabel
              {...register(`codes.${index}.${CERTIFICATION_AUTO_FORM.HAS_DECLARATION_COMPLIANCE}`)}
              control={<Checkbox checked={hasDeclarationCompliance}/>}
              label={t('dictionaries__text__certification__declarationCompliance')}
              // @ts-ignore
              onChange={handleChangeSetDeclarationCompliance}
              sx={{ margin: '16px 0 0 -4px' }}
              className='certification-code__label'
            />
          )}
          name={`codes.${index}.${CERTIFICATION_AUTO_FORM.HAS_DECLARATION_COMPLIANCE}`}
          control={control}
        />
        <br/>
        <Controller
          render={() => (
            <FormControlLabel
              {...register(`codes.${index}.${CERTIFICATION_AUTO_FORM.HAS_CERTIFICATE_STATE_REGISTRATION}`)}
              control={<Checkbox checked={hasCertificateStateRegistration}/>}
              label={t('dictionaries__text__certification__certificateOfStateRegistration')}
              // @ts-ignore
              onChange={handleChangeSetHasCertificateStateRegistration}
              sx={{ margin: '16px 0 0 -4px' }}
              className='certification-code__label'
            />
          )}
          name={`codes.${index}.${CERTIFICATION_AUTO_FORM.HAS_CERTIFICATE_STATE_REGISTRATION}`}
          control={control}
        />
        <Divider sx={{marginBottom: 0.5, marginTop: '12px' }}/>
        <div className='certification-code__row'>
          <Controller
            render={() => (
              <FormControl sx={{width: '244px'}}>
                <InputLabel
                  id='certificationScheme'>
                  {t('dictionaries__text__certification__certificationScheme')}</InputLabel>
                <Select
                  {...register(`codes.${index}.${CERTIFICATION_AUTO_FORM.CERTIFICATION_SCHEME}`, {required: hasCertificate ? false : t('common__text__isRequired')})}
                  labelId='certificationScheme'
                  id='certificationScheme'
                  value={getValues()?.codes?.[index]?.[CERTIFICATION_AUTO_FORM.CERTIFICATION_SCHEME]}
                  label={t('dictionaries__text__certification__certificationScheme')}
                  // @ts-ignore
                  onChange={({target: {value}}) => handleChangeCertificationScheme(value)}
                >
                  {optionsCertificationSchemes?.map((option: Option) => (
                    <MenuItem {...option} key={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            name={`codes.${index}.${CERTIFICATION_AUTO_FORM.CERTIFICATION_SCHEME}`}
            control={control}
          />
          <Controller
            render={() => (
              <FormControl sx={{width: '140px'}}>
                <InputLabel
                  id='certificateDuration'>{t('dictionaries__text__certification__termOfTheCertificate')}</InputLabel>
                <Select
                  {...register(`codes.${index}.${CERTIFICATION_AUTO_FORM.DOCUMENT_DURATION}`, {required: hasCertificate ? false : t('common__text__isRequired')})}
                  labelId='certificateDuration'
                  id='certificateDuration'
                  value={getValues()?.codes?.[index]?.[CERTIFICATION_AUTO_FORM.DOCUMENT_DURATION]}
                  label={t('dictionaries__text__certification__termOfTheCertificate')}
                  // @ts-ignore
                  onChange={({target: {value}}) => handleChangeDocumentDuration(value)}
                >
                  {optionsDocumentDurations?.map((option: Option) => (
                    <MenuItem {...option} key={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            name={`codes.${index}.${CERTIFICATION_AUTO_FORM.DOCUMENT_DURATION}`}
            control={control}
          />
          <Controller
            render={() => (
              <FormControl sx={{width: '160px'}}>
                <InputLabel
                  id='declarationType'
                >{t('dictionaries__text__certification__typeOfDeclaration')}</InputLabel>
                <Select
                  {...register(`codes.${index}.${CERTIFICATION_AUTO_FORM.DECLARATION_TYPE}`, {required: hasCertificate ? hasCertificate : t('common__text__isRequired')})}
                  labelId='declarationType'
                  id='declarationType'
                  value={getValues()?.codes?.[index]?.[CERTIFICATION_AUTO_FORM.DECLARATION_TYPE]}
                  label={t('dictionaries__text__certification__typeOfDeclaration')}
                  // @ts-ignore
                  onChange={({target: {value}}) => handleChangeDeclarationType(value)}>
                  {optionsDeclarationTypes?.map((option: Option) => (
                    <MenuItem {...option} key={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            name={`codes.${index}.${CERTIFICATION_AUTO_FORM.DECLARATION_TYPE}`}
            control={control}
          />
        </div>
      </>
    )}
  </div>
}

export default CertificationCode
