import React from "react";

// @ts-ignore
import Flag from 'react-world-flags'

import {COLORS} from "../../../../../config/constants";

type FlagProps = {
    code?: string
    height?: string
    marginRight?: string,
    marginTop?: string
}

export const getFlag = ({ code, height, marginRight, marginTop }: FlagProps) => {

  return <Flag
    code={code || ''}
    style={{
      height: height || '24px',
      marginRight: marginRight || '10px',
      marginTop: marginTop || 0,
      border: `1px solid ${COLORS.DARK_GRAY}`,
      boxSizing: 'content-box',
    }}
    svg='true'
  />
}
