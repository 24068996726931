import React  from "react";
import { useTranslation } from "react-i18next";
import {useFormContext} from "react-hook-form";

import TextField from "@mui/material/TextField";
import {InputAdornment} from "@mui/material";

import { PACKAGE_FORM, PackageForm } from '../add_form/helpers';

import {checkDecimal, checkTwoDecimalsAfterDot} from "../../../../../../../../../../../../../utils/validation";
import {ADORNMENTS} from "../../../../../../../../../../../../../config/constants";
import { useLazyChangePriceQuery, useLazyChangePlacesQuery } from "../../../../../../../../../../../../../api/calc";

const CommonFields = ( ) => {

  const { t } = useTranslation()

  const { register, formState, getValues, setValue, setError, clearErrors } = useFormContext<PackageForm>()

  const { errors } = formState;

  const [ changePriceRequest ] = useLazyChangePriceQuery()
  const [ changePlacesRequest ] = useLazyChangePlacesQuery()

  const errorDecimal = t('errors__message__onlyDecimals');

  const errorTwoDecimalsAfterDot = t('errors__message__incorrectValueTwoDecimalsAfterDot');

  const checkErrors = (isSuccess: boolean, field: any, errors?: any) => {
    if (isSuccess) {
      clearErrors(field)
      return
    }

    errors[field]?.[0] ? setError(field, { type: 'custom', message: errors[field]?.[0]} ) : clearErrors(field)

  }

  const handleChangePrice = (field: PACKAGE_FORM.UNIT_PRICE | PACKAGE_FORM.PRICE | PACKAGE_FORM.PIECES | PACKAGE_FORM.PLACES_MANUAL) => {

    const pieces = getValues()[PACKAGE_FORM.PIECES]
    const price = getValues()[PACKAGE_FORM.PRICE]
    const unit_price = getValues()[PACKAGE_FORM.UNIT_PRICE]

    if (field === PACKAGE_FORM.UNIT_PRICE && pieces){
      changePriceRequest({pieces, unit_price}).then((data) => {
        data?.data && setValue(PACKAGE_FORM.PRICE, data.data[PACKAGE_FORM.PRICE_TOTAL])

        // @ts-ignore
        const errorData = data?.error?.data;

        checkErrors(Boolean(data?.data), PACKAGE_FORM.PIECES, errorData)
        checkErrors(Boolean(data?.data), PACKAGE_FORM.UNIT_PRICE, errorData)

      });
    }

    if (field === PACKAGE_FORM.PRICE && pieces){
      changePriceRequest({price, pieces}).then((data) => {
        if (data?.data){
          setValue(PACKAGE_FORM.UNIT_PRICE, data.data[PACKAGE_FORM.UNIT_PRICE_TOTAL])
          setValue(PACKAGE_FORM.PIECES, data.data[PACKAGE_FORM.PIECES])
        }

        // @ts-ignore
        const errorData = data?.error?.data;

        checkErrors(Boolean(data?.data), PACKAGE_FORM.PIECES, errorData)
        checkErrors(Boolean(data?.data), PACKAGE_FORM.PRICE, errorData)
      });
    }

    if (field === PACKAGE_FORM.PIECES) {

      if (unit_price) {

        changePriceRequest({unit_price, pieces}).then((data) => {
          data?.data && setValue(PACKAGE_FORM.PRICE, data.data[PACKAGE_FORM.PRICE_TOTAL])

          // @ts-ignore
          const errorData = data?.error?.data;
          checkErrors(Boolean(data?.data), PACKAGE_FORM.PIECES, errorData)
        });
      } else if (price) {

        changePriceRequest({price, pieces}).then((data) => {
          data?.data && setValue(PACKAGE_FORM.UNIT_PRICE, data.data[PACKAGE_FORM.UNIT_PRICE_TOTAL])

          // @ts-ignore
          const errorData = data?.error?.data;

          checkErrors(Boolean(data?.data), PACKAGE_FORM.PIECES, errorData)
          checkErrors(Boolean(data?.data), PACKAGE_FORM.PRICE, errorData)
        });
      }
    }
  }

  const handleChangePlaces = (field: PACKAGE_FORM.PIECES_IN_CARTOON | PACKAGE_FORM.PLACES_MANUAL | PACKAGE_FORM.PIECES) => {
    const pieces_in_cartoon = getValues()[PACKAGE_FORM.PIECES_IN_CARTOON] // количество в 1 упаковке
    const places = getValues()[PACKAGE_FORM.PLACES_MANUAL] // количество упаковок
    const pieces = getValues()[PACKAGE_FORM.PIECES] // общее количество

    if (field === PACKAGE_FORM.PIECES_IN_CARTOON && places || field === PACKAGE_FORM.PLACES_MANUAL && pieces_in_cartoon){
      changePlacesRequest({pieces_in_cartoon, places}).then((data) => {
        data?.data && setValue(PACKAGE_FORM.PIECES, data.data['pieces_total'])

        // @ts-ignore
        const errorData = data?.error?.data;

        checkErrors(Boolean(data?.data), PACKAGE_FORM.PIECES_IN_CARTOON, errorData)
        checkErrors(Boolean(data?.data), PACKAGE_FORM.PLACES_MANUAL, errorData)

        handleChangePrice(PACKAGE_FORM.PIECES)

      });
    }

    else if (field === PACKAGE_FORM.PIECES_IN_CARTOON && pieces || field === PACKAGE_FORM.PIECES && pieces_in_cartoon){
      changePlacesRequest({pieces_in_cartoon, pieces}).then((data) => {
        data?.data && setValue(PACKAGE_FORM.PLACES_MANUAL, data.data['places_total'])

        // @ts-ignore
        const errorData = data?.error?.data;

        checkErrors(Boolean(data?.data), PACKAGE_FORM.PIECES_IN_CARTOON, errorData)
        checkErrors(Boolean(data?.data), PACKAGE_FORM.PIECES, errorData)

        handleChangePrice(PACKAGE_FORM.PIECES)

      });
    }

    else if (field === PACKAGE_FORM.PLACES_MANUAL && pieces || field === PACKAGE_FORM.PIECES && places){
      changePlacesRequest({places, pieces}).then((data) => {
        // @ts-ignore
        data?.data && setValue(PACKAGE_FORM.PIECES_IN_CARTOON, data.data['pieces_in_cartoon_total'])

        // @ts-ignore
        const errorData = data?.error?.data;

        checkErrors(Boolean(data?.data), PACKAGE_FORM.PLACES_MANUAL, errorData)
        checkErrors(Boolean(data?.data), PACKAGE_FORM.PIECES, errorData)

        handleChangePrice(PACKAGE_FORM.PIECES)

      });
    }

  }

  return <>
    <TextField
      className={`add-product__${PACKAGE_FORM.NAME}`}
      {...register(PACKAGE_FORM.NAME, { required: true, maxLength: 200 })}
      error={Boolean(errors[PACKAGE_FORM.NAME])}
      helperText={errors[PACKAGE_FORM.NAME]?.message}
      variant='outlined'
      label={t('calc__text__packaging__nameItem')}
      InputLabelProps={{ shrink: true }}
      // disabled={isLoading}
    />
    <div className='add-product__common'>
      <TextField
        className={`add-product__${PACKAGE_FORM.PIECES}`}
        {...register(PACKAGE_FORM.PIECES, { required: true, valueAsNumber: true, validate: value => checkDecimal(value, errorDecimal),  min: { value: 1, message: t('errors__message__incorrectValue')} })}
        error={Boolean(errors[PACKAGE_FORM.PIECES])}
        helperText={errors[PACKAGE_FORM.PIECES]?.message}
        variant='outlined'
        label={t('common__text__totalQuantity')}
        InputLabelProps={{ shrink: true }}
        // disabled={isLoading}
        InputProps={{
          endAdornment: <InputAdornment position='end'>{t('units__text__items')}</InputAdornment>
        }}
        onBlur={() => handleChangePlaces(PACKAGE_FORM.PIECES)}
      />
      <TextField
        className={`add-product__${PACKAGE_FORM.PLACES_MANUAL}`}
        {...register(PACKAGE_FORM.PLACES_MANUAL, { required: true, valueAsNumber: true, validate: value => checkDecimal(value, errorDecimal),  min: { value: 1, message: t('errors__message__incorrectValue')} })}
        error={Boolean(errors[PACKAGE_FORM.PLACES_MANUAL])}
        helperText={errors[PACKAGE_FORM.PLACES_MANUAL]?.message}
        variant='outlined'
        label={t('common__text__numberOfPlaces')}
        InputLabelProps={{ shrink: true }}
        // disabled={isLoading}
        InputProps={{
          endAdornment: <InputAdornment position='end'>{t('units__text__items')}</InputAdornment>
        }}
        onBlur={() => handleChangePlaces(PACKAGE_FORM.PLACES_MANUAL)}
      />
      <TextField
        className={`add-product__${PACKAGE_FORM.PIECES_IN_CARTOON}`}
        {...register(PACKAGE_FORM.PIECES_IN_CARTOON, { required: true, valueAsNumber: true, validate: value => checkDecimal(value, errorDecimal), min: { value: 1, message: t('errors__message__incorrectValue')} })}
        error={Boolean(errors[PACKAGE_FORM.PIECES_IN_CARTOON])}
        helperText={errors[PACKAGE_FORM.PIECES_IN_CARTOON]?.message}
        variant='outlined'
        label={t('calc__text__packaging__piecesInCartoon')}
        InputLabelProps={{ shrink: true }}
        // disabled={isLoading}
        InputProps={{
          endAdornment: <InputAdornment position='end'>{t('units__text__items')}</InputAdornment>
        }}
        onBlur={() => handleChangePlaces(PACKAGE_FORM.PIECES_IN_CARTOON)}
      />
    </div>
    <TextField
      className={`add-product__${PACKAGE_FORM.UNIT_PRICE}`}
      {...register(PACKAGE_FORM.UNIT_PRICE, { required: true, validate: (value: number) => checkTwoDecimalsAfterDot(value, errorTwoDecimalsAfterDot), min: { value: 0, message: t('errors__message__incorrectValue')} })}
      error={Boolean(errors[PACKAGE_FORM.UNIT_PRICE])}
      helperText={errors[PACKAGE_FORM.UNIT_PRICE]?.message}
      variant='outlined'
      label={t('common__text__pricePerPiece')}
      InputLabelProps={{ shrink: true }}
      // disabled={isLoading}
      InputProps={{
        endAdornment: ADORNMENTS.PRICE
      }}
      onBlur={() => handleChangePrice(PACKAGE_FORM.UNIT_PRICE)}
    />
    <TextField
      className={`add-product__${PACKAGE_FORM.PRICE}`}
      {...register(PACKAGE_FORM.PRICE, { required: true, validate: (value: number) => checkTwoDecimalsAfterDot(value, errorTwoDecimalsAfterDot), min: { value: 0, message: t('errors__message__incorrectValue')} })}
      error={Boolean(errors[PACKAGE_FORM.PRICE])}
      helperText={errors[PACKAGE_FORM.PRICE]?.message}
      variant='outlined'
      label={t('common__text__costOfGoods')}
      InputLabelProps={{ shrink: true }}
      // disabled={isLoading}
      InputProps={{
        endAdornment: ADORNMENTS.PRICE
      }}
      onBlur={() => handleChangePrice(PACKAGE_FORM.PRICE)}
    />
  </>
}

export default CommonFields
