import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import Completed from '../../../../common/completed';
import 'react-phone-input-2/lib/material.css'
import {CALC_ID, useOrderByIdQuery} from 'api/calc'
import Preloader from "../../../../common/preloader";
import { useGetCalcIdFromRoute } from 'utils/hooks'
import EditMainInfo, { ORDER_FORM } from './components/editMain'
import ModalBig from '../../../../common/modalBig';
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../../../../config/constants";

type Props = {
  handleAdd(): void
}

const Main = ({handleAdd} : Props) => {

  const { t } = useTranslation()

  const orderId = useGetCalcIdFromRoute()

  const { data: order, refetch, isSuccess, isError, isLoading } = useOrderByIdQuery(orderId)

  const [ showCompletedForm, setShowCompletedForm ] = useState<boolean | undefined>();

  const [ openEditModal, setOpenEditModal ] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (isError && !isLoading) {
      navigate(`/${ROUTES.CABINET}/${ROUTES.CALCULATOR}`, { replace: true });
    }
  }, [ isLoading ])

  useEffect(() => {
    if (order?.num) {
      setShowCompletedForm(true)
    }
  }, [ order?.num ])

  useEffect(() => {
    if (orderId) {
      localStorage.setItem(CALC_ID, orderId);
    }
  }, [ orderId ])


  const handleSend = () => {
    setShowCompletedForm(true)
    setOpenEditModal(false)
    refetch()
  }

  const onClose = () => {
    setShowCompletedForm(true);
    setOpenEditModal(false)
  }

  const getCompletedInfo = () => {

    const comment = order?.[ORDER_FORM.COMMENT];

    if (comment) {
      return [
        {
          label: t('common__text__comment'),
          value: comment
        },
      ]
    }

    return [];
  }

  if (!orderId) {
    return <EditMainInfo onSend={handleSend} />
  }

  if (showCompletedForm === undefined) {
    return <Preloader />
  }

  return <>
    {showCompletedForm && <Completed
      info={getCompletedInfo()}
      isFullRow
      onEdit={() => setOpenEditModal(true)}
      caption={order?.[ORDER_FORM.ORDER_NAME]}
    />}
    {openEditModal && <ModalBig isOpen={openEditModal}
      onClose={onClose}
      title={t('calc__text__main__editMainData')}
    ><EditMainInfo onSend={handleSend} hasCancelButton onClose={onClose} /></ModalBig>}
  </>
}

export default Main
