import React from "react";
import moment from "moment";
import {useTranslation} from "react-i18next";

import Typography from "@mui/material/Typography";

import {COLORS} from "../../../../../../../../../config/constants";
import {Recipient} from "../../../../../../../../../assets/svg";
import {getPluralForm} from "../../../../../../../../../utils/validation";

import './../../invoice.scss';
import {numberWithSpaces} from "../../../../../../../../../utils/helpers";

type Props = {
    invoice?: InvoiceParamsOutput
    index: number
}

const InvoiceTitle = ({invoice, index} : Props) => {

  const { t } = useTranslation()

  const invoiceId = invoice?.id;

  const PRODUCTS_DECLENSIONS: PluralForms = [ t('declension__text__itemOne'), t('declension__text__itemSeveral'), t('declension__text__itemMany') ];

  return (
    <div className='invoice-title'>
      <div className='invoice-title__left'>
        <Recipient color={invoiceId ? COLORS.GREEN : COLORS.SLIGHTLY_GRAY}/>
        <div  className='invoice-title__information'>
          <Typography variant='h6' className='invoice-title__name'>
            {`${index + 1}. ${invoiceId ? invoice?.description || '' : t('common__text__invoice')}`}
          </Typography>
          {invoice?.id && <Typography variant='body2' sx={{color: COLORS.LIGHT_TEXT_DISABLED}}>
            {`${t('common__text__invoice')}  № ${invoice?.invoice_num} ${t('common__text__from')} ${moment(invoice?.invoice_date).format('DD.MM.YYYY')}`}
          </Typography>}
        </div>
      </div>
      {invoiceId && (
        <div className='invoice-title__right'>
          <Typography variant='h6'>
            {numberWithSpaces(invoice?.price_total)} USD
          </Typography>
          <Typography variant='body2' sx={{color: COLORS.LIGHT_TEXT_DISABLED}}>
            {`${invoice.product_count} ${getPluralForm(invoice?.product_count || 0, PRODUCTS_DECLENSIONS)}`}
          </Typography>
        </div>
      )}
    </div>
  )
}

export default InvoiceTitle
