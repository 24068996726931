import React, {useState} from "react";
import {useTranslation} from "react-i18next";

import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import {COLORS, INFORMERS_CODES} from "../../../../../../../../../../../config/constants";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import {useInformers} from "../../../../../../../../../../../api/informers";
import {Divider} from "@mui/material";
import {numberWithSpaces} from "../../../../../../../../../../../utils/helpers";

type Props = {
    declaration: Declaration
}

const CustomsPayment = ({ declaration } : Props) => {

  const [ isTooltipCustomsSubtotalOpened, setIsCustomsSubtotalOpened ] = useState(false)

  const openTooltipCustomsSubtotal = () => setIsCustomsSubtotalOpened(true)

  const closeTooltipCustomsSubtotal = () => setIsCustomsSubtotalOpened(false)

  const informers = useInformers();

  const { t } = useTranslation()

  return (
    <>
      <Divider sx={{ marginBottom: 2, marginTop: 2 }} />
      <div className='customs-declaration__row customs-declaration__row_isSmall'>
        <Typography variant='body2'
          className='customs-declaration__label'>{t('common__text__customsPayment')}</Typography>
        <div className='customs-declaration__value-container'>
          <ClickAwayListener onClickAway={closeTooltipCustomsSubtotal}>
            <Tooltip
              placement='top'
              open={isTooltipCustomsSubtotalOpened}
              title={informers?.[INFORMERS_CODES.CUSTOMS_PAYMENT]}
              arrow
            >
              <Typography
                variant='body2'
                sx={{color: COLORS.BLACK_DARK, fontWeight: 500 }}
                onClick={openTooltipCustomsSubtotal}
                className='customs-declaration__text-with-tooltip'
              >{ `${numberWithSpaces(declaration.subtotal)} USD`}</Typography>
            </Tooltip>
          </ClickAwayListener>
        </div>
      </div>
    </>
  )
}

export default CustomsPayment
