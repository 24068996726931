import React, {useEffect, useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import {useFormContext} from "react-hook-form";
import {useDispatch} from "react-redux";
import debounce from "lodash/debounce";
import isArray from 'lodash/isArray'

import TextField from "@mui/material/TextField";
import {InputAdornment} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';

import {ADORNMENT_LOCATION, INFORMERS_CODES} from "../../../../../../../../../../../../../config/constants";
import {useLazyGetTnvedQuery} from "../../../../../../../../../../../../../api/tnved";
import Tooltip from '../../../../../../../../../../../../common/tooltip'
import {useInformers} from "../../../../../../../../../../../../../api/informers";
import {useInvoices, useUpdateProductById} from "../../../../../../../../../../../../../api/calc";
import {useLazyGetCountryQuery} from "../../../../../../../../../../../../../api/dictionary";
import { getProductById } from "../../../../../../../../helpers";
import { PACKAGE_FORM, PackageForm } from '../add_form/helpers';
import Excise from '../excise';
import {getCountries} from "./helpers";
import Paper from "@mui/material/Paper";

type OptionData = {
  code: string
  label: string
  id: string
}

type Props = {
  defaultCountry?: OptionData
  isAdditional: boolean
  setAdditional(value: boolean): void
  id?: number
  invoiceId?: number
  insideModal?: boolean
}

const RowCode = ( { defaultCountry, setAdditional, isAdditional, id, invoiceId, insideModal } : Props) => {

  const { t } = useTranslation()

  const { register, formState, clearErrors, setError, setValue, trigger, getValues } = useFormContext<PackageForm>()

  const { errors } = formState;

  const [ countriesRequest, { data, isLoading: isLoadingCountry, isSuccess: isSuccessCountries } ] = useLazyGetCountryQuery()

  const [ tnvedRequest, { error: errorCode } ] = useLazyGetTnvedQuery()

  const [ exciselist, setExciselist ] = useState<Excise[] | undefined>();

  const [ currentCode, setCurrentCode ] = useState("");

  const [ defaultValue ] = useState<OptionData | undefined>(defaultCountry)

  const [ userHasStartedTyping, setUserHasStartedTyping ] = useState(false);

  const informers = useInformers();

  const invoices = useInvoices();

  const dispatch = useDispatch()

  const debouncedRequest = useRef(debounce(countriesRequest, 1000)).current

  useEffect(() => {
    if (errorCode) {
      setError(PACKAGE_FORM.CODE, { type: "custom",  message: t('errors__message__incorrectHsCode') });
    } else {
      clearErrors(PACKAGE_FORM.CODE);
    }
  }, [ errorCode ])

  useEffect(() => {
    const code = getValues(PACKAGE_FORM.CODE);
    if (code) {
      handleCheckCode(code, true);
    }
  }, [ getValues(PACKAGE_FORM.CODE) ])

  const handleCheckCode = (value: string, isFirstTime?: boolean) => {

    if (value === currentCode) {
      return;
    }

    const newValue = value.replace(/\s/g, '');

    setValue(PACKAGE_FORM.CODE, newValue);
    clearErrors(PACKAGE_FORM.CODE);

    if (newValue) {
      setCurrentCode(newValue);
      tnvedRequest(newValue).then(response => {

        // @ts-ignore
        if (response.data?.Error) {
        // @ts-ignore
          setError(PACKAGE_FORM.CODE, { type: "custom",  message: t("errors__message__incorrectHsCode")});
        }

        const excise = response?.data?.GoodInfo?.Exciselist?.Excise;

        const hasEmptyExcise = !isArray(excise) && excise?.Value === "0";

        // @ts-ignore
        setAdditional(hasEmptyExcise ? false: Boolean(excise))
        if (hasEmptyExcise) { return }

        excise && setExciselist(isArray(excise) ? excise : [ excise ] );
        if (excise && !isFirstTime) {

          const product = getProductById(invoices, invoiceId, id);

          const data = {
            ...product,
            [PACKAGE_FORM.EXCISE_VALUE]: "",
            [PACKAGE_FORM.EXCISE_RATE]: {}
          };

          // @ts-ignore
          dispatch(useUpdateProductById(data));
          setValue(PACKAGE_FORM.EXCISE_VALUE, undefined);
          setValue(PACKAGE_FORM.EXCISE_RATE, undefined);
          trigger(PACKAGE_FORM.PALLETES)
        }
      });
    }
    return trigger(PACKAGE_FORM.PALLETES)
  }

  const handleCheckCountryCode = (value: string) => {
    value !== '' ? clearErrors(PACKAGE_FORM.COUNTRY_CODE) : setError(PACKAGE_FORM.COUNTRY_CODE, { type: "custom",  message: t('common__text__isRequired') })
    return trigger(PACKAGE_FORM.PALLETES)
  }

  return <>
    <div className='add-product__row-code'>
      <Autocomplete
        className={`add-product__${PACKAGE_FORM.COUNTRY_CODE}`}
        options={getCountries(data)}
        loading={isLoadingCountry}
        noOptionsText={t('calc__text__main__noOptionsFound') }
        {...register(PACKAGE_FORM.COUNTRY_CODE, { required: true })}
        defaultValue={defaultValue}
        // @ts-ignore
        onChange={(_, value: { code: string, label: string } | null ) => {
          if (!value) return
          setValue(PACKAGE_FORM.COUNTRY_CODE, value.code)
          return handleCheckCountryCode(value.label)
        }}
        filterOptions={(options) => options}
        PaperComponent={({ children }) => {
          return (
            <Paper>
              {userHasStartedTyping && isSuccessCountries && children}
            </Paper>
          )
        }}
        isOptionEqualToValue={(o: OptionData, v: OptionData) => o.code === v.code}
        loadingText={t('common__message__loadingText')}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={({ target: { value: query } }) => {
              if (query.length >= 3) {
                debouncedRequest({search: query});
                setUserHasStartedTyping(true);
              }
            }}
            error={Boolean(errors[PACKAGE_FORM.COUNTRY_CODE])}
            variant='outlined'
            label={t('calc__text__packaging__countryOfOrigin')}
            // disabled={isLoading}
            InputProps={{
              ...params.InputProps,
              startAdornment: ADORNMENT_LOCATION,
            }}
            InputLabelProps={{ shrink: true }}
          />)}/>
      <TextField
        {...register(PACKAGE_FORM.CODE, { required: true, minLength: { value: 10, message: t('common__text__codeOfHS') }, maxLength: { value: 10, message: t('common__text__codeOfHS') } })}
        className={`add-product__${PACKAGE_FORM.CODE}`}
        error={Boolean(errors[PACKAGE_FORM.CODE])}
        helperText={errors[PACKAGE_FORM.CODE]?.message}
        variant='outlined'
        onBlur={({ target: { value } }) => handleCheckCode(value)}
        onChange={({ target: { value } }) => {
          const newValue = value.replace(/\s./g, '')
          if (newValue.length >= 10) handleCheckCode(newValue)
          return newValue
        }}
        label={t('common__text__codeOfHS')}
        InputProps={{ endAdornment: <InputAdornment position='end'><Tooltip content={informers?.[INFORMERS_CODES.TNVED_CODE]} /></InputAdornment> }}
        // disabled={isLoading}
        InputLabelProps={{ shrink: true }}
      />
    </div>
    {isAdditional && <Excise data={exciselist} id={id} invoiceId={invoiceId} insideModal={insideModal} />}
  </>
}

export default RowCode
