import React from "react";
import cx from 'classnames'

import Typography from "@mui/material/Typography";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Completed from '../../../../../common/completed';
import {COLORS} from "../../../../../../config/constants";

import './summary_item.scss';

type SummaryItemGeneral = {
    title: string
    price: React.ReactNode
}

type SummaryItem = {
    title: string
    price?: React.ReactNode
    additional?: SummaryItemGeneral[]
    notification?: React.ReactNode
}

type Props = {
    item: SummaryItem;
}

const SummaryItem = ({item} : Props) => {

  if (!item.additional) {
    return (<div>
      <div className={cx('summary-calculator__item',
        {'summary-calculator__item_notification': item.notification}
      )}>
        <Typography variant='subtitle1'>
          {item.title}
        </Typography>
        {item.price && <Typography variant='subtitle1' sx={{marginRight: 4}}>
          {item.price}
        </Typography>}
      </div>
      {item.notification && <div className='summary-calculator__item_notificaiton-container'>
        {item.notification}
      </div>}
    </div>);
  }

  const getCompletedInfo = (items: SummaryItemGeneral[]) => items.map(item => (
    {
      label: item.title,
      value: item.price,
    }
  ))

  return (
    <Accordion sx={{
      boxShadow: 'none',
      marginBottom: 0,
      borderBottom: `1px solid ${COLORS.SLIGHTLY_GRAY}`,
      '&:before': {display: 'none'},
      '&:last-child': {
        marginBottom: 0,
        borderBottom: 'none'
      },
      '&.Mui-expanded': {
        marginBottom: 0,
      }
    }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          height: '58px',
          padding: '0px 20px 0 16px',
          '&.Mui-expanded': {
            borderBottom: 'none'
          }
        }}
      >
        <div className='summary-calculator__item summary-calculator__item_no-padding'>
          <Typography variant='subtitle1'>
            {item.title}
          </Typography>
          <Typography variant='subtitle1'>
            {item.price}
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: '0px 16px 24px 24px' }}>
        <ul className='summary-calculator__additional'>
          <Completed
            info={getCompletedInfo(item.additional)}
            isFullRow
          />
        </ul>
      </AccordionDetails>
    </Accordion>
  )
}

export default SummaryItem
