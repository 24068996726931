import React, {useState} from "react";
import { useTranslation } from "react-i18next";

import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import {useTnvedCodes} from "../../../../../../../../../api/tnved";
import {formatHSCode} from "../../../../../../../../../utils/helpers";

type Props = {
    index: number
    code: string
    country?: string
    onChange(value: boolean): void
}

const CardHasCertificate = ({ index, code, country, onChange }: Props) => {

  const { t } = useTranslation()

  const [ hasCertificate, setHasCertificate ] = useState(true);

  const codes = useTnvedCodes();

  const foundCodeObject = codes.find((obj) => Object.prototype.hasOwnProperty.call(obj, code));

  const handleChange = (event: React.SyntheticEvent, checked: boolean) => {
    setHasCertificate(!hasCertificate);
    onChange(checked);
  }

  return <div className='certification-code certification-code_hasCertificate'>
    <Typography variant='subtitle1' gutterBottom sx={{ marginBottom: '4px' }}>
      {index + 1}. {t('common__text__codeOfHS')} {formatHSCode(code)} ({country})
    </Typography>
    <Typography variant='body2' gutterBottom sx={{ padding: '0 0 0 20px' }}>
      {foundCodeObject?.[code]?.GoodInfo.Name}
    </Typography>
    <FormControlLabel
      control={<Checkbox checked={hasCertificate}/>}
      onChange={handleChange}
      label={t('dictionaries__text__certification__thereIsAValidCertificate')}
      sx={{ margin: '8px 0 0 -4px' }}
      className='certification-code__label'
    />
  </div>
}

export default CardHasCertificate
