import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import cx from 'classnames'

import AddForm from './components/add_form';

import Completed from "../../../../../../../../common/completed";
import ModalBig from "../../../../../../../../common/modalBig";

import {getFlag} from "../../../../../delivery/helpers";
import ModalCode from "../../../modal_code";
import {useLazyGetTnvedQuery} from "../../../../../../../../../api/tnved";
import CustomsCost from "./components/customs_cost";
import CustomsPayment from "./components/customs_payment";

import './customs_declaration.scss';
import {formatHSCode, numberWithSpaces} from "../../../../../../../../../utils/helpers";

type Props = {
  isCompleted: boolean,
  declaration: Declaration
  index: number
  count: number
  feesTotal: number
  onSave(): void
  setIsConfirmButtonDisable(value: boolean): void
}

const CustomsDeclaration = ( { isCompleted, declaration, index, onSave, count, feesTotal, setIsConfirmButtonDisable } : Props ) => {

  const { t } = useTranslation()

  const [ shouldOpenModal, setShouldOpenModal ] = useState(false)

  const [ openModalCode, setOpenModalCode ] = useState(false);

  const [ tnvedInfo, setTnvedInfo ] = useState<TnvedInfo>();

  const [ tnvedRequest ] = useLazyGetTnvedQuery()

  useEffect(() => {
    tnvedRequest(declaration.code)?.then((response) => {
      setTnvedInfo(response?.data);
    });
  }, [])

  const exciseValue = Number((declaration.excise_value * declaration.excise).toFixed(2));

  const info = [
    {
      label: `${t('common__text__codeOfHS')}`,
      value: <a
        className='customs-declaration__link'
        onClick={() => setOpenModalCode(true)}>{formatHSCode(declaration.code)}</a>,
    },
    {
      label: `${t('calc__text__customs__customsCountryOfManufacture')}`,
      value: declaration.country_data.name,
    },
    {
      label: t('calc__text__customs__customsCost'),
      value: <CustomsCost declaration={declaration}/>
    },
    ...(exciseValue ? [ {
      label: t('calc__text__customs__excise'),
      value: `${numberWithSpaces(exciseValue)} USD`
    } ] : []),
    {
      label: `${t('calc__text__customs__dutyRate')} / ${t('calc__text__customs__dutyAmount')}`,
      value: `${declaration.import_tax?.description} / ${numberWithSpaces(declaration.duty)} USD`
    },
    {
      label: `${t('common__text__vatRate')} / ${t('calc__text__customs__vatAmount')}`,
      value: `${declaration.vat_rate}% / ${numberWithSpaces(declaration.vat)} USD`,
    },

    {
      label: t('calc__text__customs__certificateOfOrigin'),
      value: declaration.origin_certificate ? t('common__text__yes') : t('common__text__no')
    },
  ]

  if (isCompleted) {
    return  <div  className={cx('customs-declaration__completed', {
      'customs-declaration__completed_notFirst': index >= 1,
    })}>
      <Completed
        info={info}
        onEdit={() => setShouldOpenModal(true)}
        isFullRow
        caption={<span className='customs-declaration__goodsName'>
          {index + 1}. {declaration.product_names.join(', ')}
        </span>}
        bottom={<CustomsPayment declaration={declaration}/>}
      />
      <ModalCode
        isOpen={openModalCode}
        onClose={() => setOpenModalCode(false)}
        insideModal
        code={declaration.code}
        tnvedInfo={tnvedInfo}
      />
      {shouldOpenModal && <ModalBig
        isOpen
        onClose={() => setShouldOpenModal(false)}
        title={`${t('calc__text__customs__editDeclaration')}` }
      >
        <AddForm
          onCancel={() => setShouldOpenModal(false)}
          index={index}
          declaration={declaration}
          onSave={onSave}
          count={count}
          feesTotal={feesTotal}
          insideModal
        />
      </ModalBig>}
    </div>
  }

  return <AddForm
    index={index}
    declaration={declaration}
    onSave={onSave}
    count={count}
    feesTotal={feesTotal}
    setIsConfirmButtonDisable={setIsConfirmButtonDisable}
  />;

}

export default CustomsDeclaration
