import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from 'api'


export const localizationApi = createApi({
  baseQuery,
  reducerPath: 'localizationApi',
  endpoints: (builder) => ({
    getDictionary: builder.query<any, string>({
      query: (query) => `localization/v1.0/${query}/`,
    }),
  }),
})

export const { useLazyGetDictionaryQuery } = localizationApi
