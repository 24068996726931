import React, {useState, useEffect} from "react";
import { useTranslation } from "react-i18next";
import { useForm, useFieldArray, FormProvider } from "react-hook-form";

import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import DeleteIcon from '@mui/icons-material/Delete';

import {CertificationType} from "../../index";
import RadioTemplate from "../radio-template";
import CertificationCode from "./components/certification-code";
import ModalBig from "../../../../../../common/modalBig";
import ModalSearch from "./components/modalSearch";
import CompanyCard from "./components/company-card";
import {
  useCertificationCentersSearch,
  useCurrentCertCenters,
  useDeclarations, useGetCertificationCenterSearchByOrderIdQuery,
  useGetCertificationCentersMutation,
  useGetCurrentCertificationByOrderIdQuery, useLazyDeclarationSummaryByOrderIdQuery, useSetCertificationCenterMutation,
  useSetCertificationCenterSearchMutation,
  useSetCompletedByOrderIdMutation,
} from "../../../../../../../api/calc";
import {useGetCalcIdFromRoute} from "../../../../../../../utils/hooks";

import CardHasCertificate from "./components/card-has-certificate";
import {numberWithSpaces} from "../../../../../../../utils/helpers";

export enum CERTIFICATION_AUTO_FORM {
  DECLARATION_TYPE = 'declaration_type',
  CERTIFICATION_SCHEME = 'certification_scheme',
  DOCUMENT_DURATION = 'document_duration',
  CODE = 'code',
  COUNTRY_CODE = 'country_code',
  HAS_CERTIFICATE = 'has_certificate',
  HAS_CODE_COMPLIANCE = 'has_code_compliance',
  HAS_DECLARATION_COMPLIANCE = 'has_declaration_compliance',
  HAS_CERTIFICATE_STATE_REGISTRATION = 'has_certificate_state_registration',
}

export type CertificationAutoForm = {
  [CERTIFICATION_AUTO_FORM.DECLARATION_TYPE]: string
  [CERTIFICATION_AUTO_FORM.CERTIFICATION_SCHEME]: string,
  [CERTIFICATION_AUTO_FORM.DOCUMENT_DURATION]: string
  [CERTIFICATION_AUTO_FORM.CODE]: string
  [CERTIFICATION_AUTO_FORM.COUNTRY_CODE]: string
  [CERTIFICATION_AUTO_FORM.HAS_CERTIFICATE]: boolean,
  [CERTIFICATION_AUTO_FORM.HAS_CODE_COMPLIANCE]: boolean,
  [CERTIFICATION_AUTO_FORM.HAS_DECLARATION_COMPLIANCE]: boolean,
  [CERTIFICATION_AUTO_FORM.HAS_CERTIFICATE_STATE_REGISTRATION]: boolean,
}

export type CertificationFormType = {
  codes: CertificationAutoForm[]
}

type Props = {
  isExpanded: boolean
  value?: number
  isActive: boolean
  handleChangeAccordion(value: CertificationType, isExpanded: boolean): void
  handleComplete(): void
  handleChange(): void
}

const CertificationAuto = ({ isExpanded, handleChangeAccordion, handleComplete, handleChange, isActive, value }: Props) => {

  const { t } = useTranslation()

  const orderId = useGetCalcIdFromRoute()

  const [ shouldOpenModal, setShouldOpenModal ] = useState(false)

  const [ shouldShowCompletedInfo, setShouldShowCompletedInfo ] = useState(false);

  const { refetch: fetchCurrentCertification } = useGetCurrentCertificationByOrderIdQuery(orderId)
  const { refetch: fetchCertificationCenter } = useGetCertificationCenterSearchByOrderIdQuery(orderId)
  const [ triggerFetchSummaryDeclaration ] = useLazyDeclarationSummaryByOrderIdQuery();

  const [ centers, setCenters ] = React.useState();

  const [ codes, setCodes ] = React.useState<Declaration[]>([]);

  const [ allCodesHaveCertificates, setAllCodesHaveCertificates ] = React.useState(false);

  const [ codesHasLoaded, setCodesHasLoaded ] = React.useState(false);

  const [ getCenters ] = useGetCertificationCentersMutation()
  const [ saveSearch ] = useSetCertificationCenterSearchMutation()
  const [ setCertificationCenter ] = useSetCertificationCenterMutation()

  const certCentersParams = useCertificationCentersSearch()

  const declarations = useDeclarations();

  const currentCertCenters = useCurrentCertCenters();

  const [ setCompleted ] = useSetCompletedByOrderIdMutation()

  useEffect(() => {
    if (currentCertCenters?.length) {
      setShouldShowCompletedInfo(true);
    }
  }, [ currentCertCenters ])

  const methods = useForm<CertificationFormType>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      codes: codes.map((c) => (
        {
          [CERTIFICATION_AUTO_FORM.HAS_CERTIFICATE]: false,
          [CERTIFICATION_AUTO_FORM.HAS_CODE_COMPLIANCE]: false,
          [CERTIFICATION_AUTO_FORM.HAS_DECLARATION_COMPLIANCE]: false,
          [CERTIFICATION_AUTO_FORM.HAS_CERTIFICATE_STATE_REGISTRATION]: false,
          [CERTIFICATION_AUTO_FORM.CODE]: '',
        }
      ))
    },
  });

  const { handleSubmit, control, watch, getValues, setValue, reset, formState: { isValid } } = methods;

  const { fields } = useFieldArray({
    control,
    // @ts-ignore
    name: "codes",
  });

  useEffect(() => {
    // первоначальное установление кодов в связи с информацией в декларации
    if (declarations?.length){
      let declarationsCodes: Declaration[] = [];
      declarations?.map((declaration) => {
        declarationsCodes = [ ...codes, ...declaration.codes ];
      })
      setCodes(declarationsCodes);
    }
  }, [ declarations ]);

  useEffect(() => {
    // установление параметров по умолчанию для формы
    const updatedDefaultValues = {
      codes: codes.map((code) => ({
        [CERTIFICATION_AUTO_FORM.HAS_CERTIFICATE]: false,
        [CERTIFICATION_AUTO_FORM.HAS_CODE_COMPLIANCE]: false,
        [CERTIFICATION_AUTO_FORM.HAS_DECLARATION_COMPLIANCE]: false,
        [CERTIFICATION_AUTO_FORM.HAS_CERTIFICATE_STATE_REGISTRATION]: false,
        [CERTIFICATION_AUTO_FORM.CODE]: code.code,
        [CERTIFICATION_AUTO_FORM.COUNTRY_CODE]: code.country_code,
        [CERTIFICATION_AUTO_FORM.DECLARATION_TYPE]: '',
        [CERTIFICATION_AUTO_FORM.CERTIFICATION_SCHEME]: '',
        [CERTIFICATION_AUTO_FORM.DOCUMENT_DURATION]: '',
      })),
    };

    if (codes.length && !codesHasLoaded){
      reset(updatedDefaultValues);
      setCodesHasLoaded(true);
    }
  }, [ codes ]);

  useEffect(() => {
    // обновление данных формы, если пришли currentCertCenters (нужно для отображения has_certificate = true)
    if (currentCertCenters?.length) {
      const updatedDefaultValues = {
        codes: getValues().codes.map((code) => {
          const foundedCertCenter = currentCertCenters?.find(currentCertCenter => currentCertCenter.code === code.code);
          return ({
            ...code,
            [CERTIFICATION_AUTO_FORM.HAS_CERTIFICATE]: foundedCertCenter?.[CERTIFICATION_AUTO_FORM.HAS_CERTIFICATE] || false,
            [CERTIFICATION_AUTO_FORM.HAS_CODE_COMPLIANCE]: foundedCertCenter?.[CERTIFICATION_AUTO_FORM.HAS_CODE_COMPLIANCE] || false,
            [CERTIFICATION_AUTO_FORM.HAS_DECLARATION_COMPLIANCE]: foundedCertCenter?.[CERTIFICATION_AUTO_FORM.HAS_DECLARATION_COMPLIANCE] || false,
            [CERTIFICATION_AUTO_FORM.HAS_CERTIFICATE_STATE_REGISTRATION]: foundedCertCenter?.[CERTIFICATION_AUTO_FORM.HAS_CERTIFICATE_STATE_REGISTRATION] || false,
          })
        }),
      };
      reset(updatedDefaultValues);
    }
  }, [ currentCertCenters ]);

  const watchFieldArray = watch("codes");

  React.useEffect(() => {
    let hasTriggered = false;
    // отправка запросов на сохранение данных, если у всех кодов есть сертификаты
    const subscription = watch(() => {
      const newValuesAllHaveCertificates = getValues().codes.every(item => item.has_certificate);

      if (newValuesAllHaveCertificates && !allCodesHaveCertificates && !hasTriggered) {
        setAllCodesHaveCertificates(true);
        hasTriggered = true;

        const data = getValues().codes.map(item => ({
          code: item.code,
          has_certificate: true,
        }));

        (async () => {
          await setCertificationCenter({
            orderId,
            body: data,
          });

          await saveSearch({
            orderId,
            request_data: {
              code_params: []
            }
          })

          await setCompleted({
            orderId,
            certification: true,
          });

          triggerFetchSummaryDeclaration(orderId);
        })();

      } else if (!newValuesAllHaveCertificates && allCodesHaveCertificates) {
        setAllCodesHaveCertificates(false);
      }
    });

    handleChange();

    return () => subscription.unsubscribe();

  }, [ watch, allCodesHaveCertificates, orderId ]);

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index]
    };
  });

  const onSubmit = handleSubmit (async (values) => {

    const codeParams = values.codes
      .filter((obj) => !obj.has_certificate)
      .map((obj) => {
        return Object.fromEntries(
          Object.entries(obj)
            .filter(([ key, value ]) => key !== "has_certificate" && value !== "")
        );
      });

    const data = {
      "code_params": codeParams
    }

    await saveSearch({
      orderId,
      request_data: {
        code_params: codeParams
      }
    })

    await getCenters({orderId, ...data}).then((data) => {
      // @ts-ignore
      setCenters(data?.data)
    });

    await setCompleted({
      orderId,
      certification: false
    })

    setShouldOpenModal(true);

  })

  const handleSearch = async (values: any) => {
    if (shouldShowCompletedInfo) {

      const data = {
        "code_params": certCentersParams
      }

      await getCenters({orderId, ...data}).then((data) => {
        // @ts-ignore
        setCenters(data?.data)
      });

      await setCompleted({
        orderId,
        certification: false
      })

      setShouldOpenModal(true);

    } else {
      onSubmit(values);
    }
  }

  const handleOpenAccordion = (isExpanded: boolean) => {
    handleChangeAccordion(CertificationType.AUTO, isExpanded);
  }

  const handleClose = () => setShouldOpenModal(false);

  const handleRemoveCenter = () => {

    const updatedDefaultValues = {
      codes: codes.map((code) => ({
        [CERTIFICATION_AUTO_FORM.HAS_CERTIFICATE]: false,
        [CERTIFICATION_AUTO_FORM.CODE]: code.code,
        [CERTIFICATION_AUTO_FORM.COUNTRY_CODE]: code.country_code,
        [CERTIFICATION_AUTO_FORM.DECLARATION_TYPE]: '',
        [CERTIFICATION_AUTO_FORM.CERTIFICATION_SCHEME]: '',
        [CERTIFICATION_AUTO_FORM.DOCUMENT_DURATION]: '',
      })),
    };

    reset(updatedDefaultValues);
    setShouldShowCompletedInfo(false);
  }

  const currentCodesWithCertificate = currentCertCenters?.filter((c) => c.has_certificate);

  const currentCodesWithoutCertificate = currentCertCenters?.filter((c) => c.certification_center)

  return <div className='certification-auto'>
    <RadioTemplate
      isActive={isActive}
      title={<Typography variant='h6' sx={{
        fontWeight: '500',
        marginLeft: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
      }}>
        {t('dictionaries__text__certification__findOutTheCostOfCertification')}
        <span className='certification-manual__total'>
          {value ? `${numberWithSpaces(value)} USD` : undefined}
        </span>
      </Typography>}
      value={CertificationType.AUTO}
      isExpanded={isExpanded}
      handleChangeAccordion={handleOpenAccordion}
    >
      <div className='certification-auto__container'>
        <FormProvider {...methods}>
          {shouldShowCompletedInfo ?
            <>
              {currentCodesWithCertificate?.map((item, index) => <CardHasCertificate
                index={index}
                key={index}
                code={item.code}
                country={codes?.find(code => code.code === item.code)?.country_code}
                onChange={(value) => {
                  const targetIndex = getValues().codes.findIndex(codeValue => codeValue.code === item.code);
                  if (targetIndex !== -1) {
                    // @ts-ignore
                    setValue(`codes.${targetIndex}.${CERTIFICATION_AUTO_FORM.HAS_CERTIFICATE}`, value);
                  }
                  setShouldShowCompletedInfo(false)
                }}
              />)}
              {!getValues().codes.every(item => item.has_certificate) && <CompanyCard isActive currentCenter={currentCodesWithoutCertificate?.[0]}/>}
            </>
            :
            controlledFields.map((item, index) => <CertificationCode
              index={index}
              key={index}
              code={codes[index]}
            />)
          }
        </FormProvider>
        {!allCodesHaveCertificates && <Button
          variant='outlined'
          fullWidth
          startIcon={<SearchIcon/>}
          disabled={shouldShowCompletedInfo ? false : !isValid}
          onClick={handleSearch}
        >{shouldShowCompletedInfo ? t('dictionaries__text__certification__findOtherOptionsForTheCostOfCertification') : t('dictionaries__text__certification__findOptionsForTheCostOfCertification')}</Button>}
        {shouldShowCompletedInfo && <Button
          variant='contained'
          fullWidth
          startIcon={<DeleteIcon/>}
          onClick={handleRemoveCenter}
          sx={{ marginTop: allCodesHaveCertificates ? 0 : 2 }}
        >{t('dictionaries__text__certification__resetTheCostOfCertification')}</Button>}
      </div>
    </RadioTemplate>
    <ModalBig
      isOpen={Boolean(shouldOpenModal)}
      onClose={handleClose}
      title={t('dictionaries__text__certification__searchForCertificationServices')}
      isSearch
    >
      <FormProvider {...methods}>
        <ModalSearch
          preloadCenters={centers}
          handleClose={handleClose}
          handleComplete={handleComplete}
        />
      </FormProvider>
    </ModalBig>
  </div>
}

export default CertificationAuto
