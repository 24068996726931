import React, {useEffect} from "react";

import CheckIcon from "@mui/icons-material/Check";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {useLazyGetDictionaryQuery} from "../../../../../api/localization";
import Preloader from "../../../../common/preloader";
import isEmpty from "lodash/isEmpty";

import './interface.scss'
import moment from "moment/moment";

enum INTERFACE_FORM {
    LANGUAGE = 'language',
    TIMEZONE = 'timezone',
}

type InterfaceForm = {
    language: string
    timezone: string
}

type OptionData = {
    label: string
    id: string
}

const Interface = () => {

  const { t } = useTranslation()

  const { i18n } = useTranslation();

  const [ localizationGetLanguagesRequest, { data: languages } ] = useLazyGetDictionaryQuery();
  const [ localizationGetTimezonesRequest, { data: timezones } ] = useLazyGetDictionaryQuery();

  const { register, handleSubmit, setValue, getValues, formState: { errors, isValid } } = useForm<InterfaceForm>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      [INTERFACE_FORM.LANGUAGE]: i18n.resolvedLanguage.toUpperCase(),
    }
  })

  useEffect(() => {
    localizationGetLanguagesRequest('languages');
    // @ts-ignore
    localizationGetTimezonesRequest('timezones').then((data) => setValue(INTERFACE_FORM.TIMEZONE, data.data[0].code));
  }, []);

  const languagesOptions = (languages ?? [])
    .map(({ name, code }: LocationItem) => {
      return ({ id: code, label: name});
    })

  const selectedValue = React.useMemo(
    // @ts-ignore
    () => languagesOptions.find((value) => value.id === i18n.resolvedLanguage.toUpperCase()),
    [ languagesOptions ],
  );

  const timezoneOptions = (timezones ?? [])
    .map(({ city, code, tzoffset }: TimezoneItem) => {
      return ({ id: code, label: `${city}, ${code}, ${tzoffset}`});
    })

  if (!languages?.length || !timezones?.length) {
    return <Preloader />;
  }

  const checkIsValid = () => isEmpty(errors)

  const handleChangeLanguage = (value: string) => {
    i18n.changeLanguage(value.toLowerCase());
    moment.locale(value.toLowerCase());
    localStorage.setItem('locale', value.toLowerCase());
  }

  const onSubmit = handleSubmit (async (values) => {
    handleChangeLanguage(values[INTERFACE_FORM.LANGUAGE])
  })

  return (
    <form className='settings-interface' onSubmit={onSubmit}>
      <Typography
        variant='h6'
        className='settings-interface__title'
      >
        {t('settings__text__interfaceLanguageTimezone')}
      </Typography>
      <Autocomplete
        className={`settings-interface__${INTERFACE_FORM.LANGUAGE}`}
        options={languagesOptions}
        noOptionsText={t('calc__text__main__noOptionsFound') }
        value={selectedValue}
        {...register(INTERFACE_FORM.LANGUAGE, { required: true })}
        // @ts-ignore
        onChange={(_, value: { id: string } | null ) => {
          // @ts-ignore
          setValue(INTERFACE_FORM.LANGUAGE, value?.id);
        }}
        loadingText={t('common__message__loadingText')}
        renderInput={(params) => (
          <TextField
            {...params}
            error={Boolean(errors[INTERFACE_FORM.LANGUAGE])}
            variant='outlined'
            label={t('settings__text__interfaceLanguage')}
            InputProps={{
              ...params.InputProps,
            }}
            InputLabelProps={{ shrink: true }}
          />)}/>
      <Autocomplete
        className={`settings-interface__${INTERFACE_FORM.TIMEZONE}`}
        options={timezoneOptions}
        noOptionsText={t('calc__text__main__noOptionsFound') }
        defaultValue={timezoneOptions[0]}
        {...register(INTERFACE_FORM.TIMEZONE, { required: true })}
        // @ts-ignore
        onChange={(_, value: { id: string } | null ) => setValue(INTERFACE_FORM.TIMEZONE, value?.id)}
        loadingText={t('common__message__loadingText')}
        renderInput={(params) => (
          <TextField
            {...params}
            error={Boolean(errors[INTERFACE_FORM.TIMEZONE])}
            variant='outlined'
            label={t('settings__text__timezone')}
            InputProps={{
              ...params.InputProps,
            }}
            InputLabelProps={{ shrink: true }}
          />)}/>
      <Button
        className='settings-auth__button'
        variant='contained'
        fullWidth
        type='submit'
        startIcon={<CheckIcon />}
        disabled={!checkIsValid()}
      >{t('actions__text__saveInterfaceSettings')}</Button>
    </form>
  )
}

export default Interface
