import {useTranslation} from "react-i18next";

import moment from "moment/moment";
import {numberWithSpaces} from "../../../../../utils/helpers";

export const getDeliveryInfo = (summaryDelivery?: Route | Record<string, never>) => {

  const { t } = useTranslation();

  if (!summaryDelivery || !summaryDelivery.freights) {
    return []
  }

  const uniqueNames = summaryDelivery.freights
    .map((freight) => freight.delivery_company.name)
    .filter((name, index, array) => array.indexOf(name) === index);

  const companyNames = uniqueNames.join(", ");

  return [
    [
      {
        label: t('calc__text__rightPanel__from'),
        value: summaryDelivery?.location_from?.name,
      },
      {
        label: t('calc__text__rightPanel__to'),
        value: summaryDelivery?.location_to?.name,
      },
      {
        label: t('common__text__date'),
        value: summaryDelivery?.planned_shipment_date ? moment(summaryDelivery?.planned_shipment_date).format('DD.MM.YYYY') : ''
      },
    ],
    [
      {
        label: t('common__text__company'),
        value: companyNames
      },
      ...(summaryDelivery?.planned_delivery_date
        ? [ {
          label: t('calc__text__delivery__arrivalDate'),
          value: moment(summaryDelivery?.planned_delivery_date).format('DD.MM.YYYY')
        } ]
        : []
      ),
      {
        label: t('calc__text__rightPanel__preliminaryCostOfDelivery'),
        value: `${numberWithSpaces(summaryDelivery?.total_price)} USD`
      },
    ]
  ]

}
