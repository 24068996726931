import React, { useState } from "react";
import moment from "moment";
import cx from 'classnames'
import {useTranslation} from "react-i18next";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

import {COLORS} from "../../../config/constants";
import Template from './components/template';
import { getMainInfo } from "./components/mainInfo";
import { getPackagingInfo } from "./components/packagingInfo";
import { getDeliveryInfo } from "./components/deliveryInfo";
import { getCertificateInfo } from "./components/certificateInfo";
import { getCustomsInfo } from "./components/customsInfo";
import { getBankCommissionsInfo } from "./components/bankCommissionsInfo";
import { getAdditionalInfo } from "./components/additionalInfo";

import './right-panel.scss';
import {numberWithSpaces} from "../../../utils/helpers";

enum CURRENT_STEP {
  MAIN = 'main',
  PACKAGING = 'packaging',
  DELIVERY = 'delivery',
  CUSTOMS = 'customs',
  CERTIFICATE = 'certificate',
  BANK_COMMISSIONS = 'bank_commissions',
  ADDITIONAL = 'additional',
}

type Props = {
  noInfo?: boolean
  order?: OrderParamsOutput
  invoices?: InvoiceParamsOutput[]
  summaryOrder?: SummaryParamsOutput | Record<string, never>
  summaryCustoms?: DeclarationSummaryParamsOutput | Record<string, never>
  summaryDelivery?: Route | Record<string, never>
  summaryCompleted?: Completed
  declarations?: DeclarationsParamsOutput[]
  noLimeColor?: boolean
}

const SummaryCalculator = ({
  noInfo,
  order,
  summaryCustoms,
  summaryOrder,
  invoices,
  summaryDelivery,
  summaryCompleted,
  declarations,
  noLimeColor
}: Props) => {

  const { t } = useTranslation()

  const [ currentStep, setCurrentStep ] = useState<CURRENT_STEP | undefined>(undefined)

  const handleChangeStep = (step: CURRENT_STEP) => setCurrentStep(currentStep === step ? undefined : step)

  const isCompleted = summaryCompleted?.common && summaryCompleted?.packaging &&
      summaryCompleted.delivery && summaryCompleted.customs &&
      summaryCompleted.additional_services && summaryCompleted.banking_commissions &&
      summaryCompleted.certification;

  return (
    <Card className={cx('summary-total')} sx={{
      boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.20), ' +
          '0px 1px 1px 0px rgba(0, 0, 0, 0.14), ' +
          '0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
    }}>
      {!noInfo && order?.id && <>
        <CardContent sx={{ padding: '16px 24px 0'}}>
          <Typography variant='h6'>
            {order.order_name}
          </Typography>
          <Typography variant='caption'>
            {`№ ${order?.id} ${t('common__text__from')} ${moment(order?.created_at).format(`Do MMMM YYYY, HH:mm:ss `)}` }
          </Typography>
        </CardContent>
        <Divider/>
      </>}
      <Template
        text={`1. ${t('calc__text__step1__name')}`}
        isCompleted={summaryCompleted?.common}
        info={getMainInfo(order)}
        isExpanded={currentStep === CURRENT_STEP.MAIN}
        onClick={() => handleChangeStep(CURRENT_STEP.MAIN)}
        inProgress={Boolean(order?.id)}
        noLimeColor={noLimeColor}/>
      <Template
        text={`2. ${t('calc__text__step2__name')}`}
        isCompleted={summaryCompleted?.packaging}
        info={getPackagingInfo(summaryCompleted?.packaging || false, invoices, summaryOrder)}
        isExpanded={currentStep === CURRENT_STEP.PACKAGING}
        onClick={() => handleChangeStep(CURRENT_STEP.PACKAGING)}
        noLimeColor={noLimeColor}
      />
      <Template
        text={`3. ${t('calc__text__step3__name')}`}
        isCompleted={summaryCompleted?.delivery}
        info={getDeliveryInfo(summaryDelivery)}
        isExpanded={currentStep === CURRENT_STEP.DELIVERY}
        onClick={() => handleChangeStep(CURRENT_STEP.DELIVERY)}
        noLimeColor={noLimeColor}
      />
      <Template
        text={`4. ${t('calc__text__step4__name')}`}
        isCompleted={summaryCompleted?.customs}
        info={getCustomsInfo(summaryCustoms, declarations)}
        isExpanded={currentStep === CURRENT_STEP.CUSTOMS}
        onClick={() => handleChangeStep(CURRENT_STEP.CUSTOMS)}
        noLimeColor={noLimeColor}
      />
      <Template
        text={`5. ${t('calc__text__step5__name')}`}
        isCompleted={summaryCompleted?.certification}
        info={getCertificateInfo(summaryCustoms)}
        isExpanded={currentStep === CURRENT_STEP.CERTIFICATE}
        onClick={() => handleChangeStep(CURRENT_STEP.CERTIFICATE)}
        noLimeColor={noLimeColor}
      />
      <Template
        text={`6. ${t('calc__text__step6__name')}`}
        isCompleted={summaryCompleted?.banking_commissions}
        info={getBankCommissionsInfo(order?.bankCommissions, summaryOrder?.total_payment_amount_with_commissions)}
        isExpanded={currentStep === CURRENT_STEP.BANK_COMMISSIONS}
        onClick={() => handleChangeStep(CURRENT_STEP.BANK_COMMISSIONS)}
        noLimeColor={noLimeColor}
      />
      <Template
        text={`7. ${t('calc__text__step7__name')}`}
        isCompleted={summaryCompleted?.additional_services}
        info={getAdditionalInfo(order?.userExtraServices)}
        isExpanded={currentStep === CURRENT_STEP.ADDITIONAL}
        onClick={() => handleChangeStep(CURRENT_STEP.ADDITIONAL)}
        noLimeColor={noLimeColor}
      />
      {!noInfo && summaryOrder?.total_amount && <>
        <CardContent sx={{
          backgroundColor: isCompleted ? COLORS.LIME : COLORS.WHITE,
          '&:last-child': {
            padding: '16px 24px',
          }
        }}>
          <Typography variant='subtitle1' className={cx('right-panel__total', {
            'right-panel__total_isCompleted': isCompleted,
          })}>
            <span className='right-panel__total-label'>
              <InfoOutlined sx={{
                fill:COLORS.LIGHT_TEXT_DISABLED_OPACITY,
                marginRight: '12px',
              }} />
              {t('common__text__inTotal').toUpperCase()}
            </span>
            <span className='right-panel__total-value'>{`${numberWithSpaces(summaryOrder?.total_amount)} USD`}</span>
          </Typography>
        </CardContent>
      </>}
    </Card>
  )
}

export default SummaryCalculator
