import React, {useEffect, useState} from 'react'
import { useForm } from "react-hook-form";

import Typography from '@mui/material/Typography';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Link from '@mui/material/Link';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import InputAdornment from "@mui/material/InputAdornment";
import TextField from '@mui/material/TextField'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useAuthContext } from "../../../context/AuthContext";
import {setToken, useSignInMutation} from "../../../api/auth";
import { useNavigate } from "react-router-dom";
import isEmpty from 'lodash/isEmpty'

import './../auth.scss'
import {useTranslation} from "react-i18next";
import Notification, {NOTIFICATION_TYPE} from "../../common/notification";
import {ROUTES} from "../../../config/constants";
import {EMAIL_PATTERN} from "../../../utils/validation";

enum LOGIN_FORM {
  EMAIL = 'email',
  PASSWORD = 'password',
  REMEMBER = 'remember',
}

type LoginForm = {
  email: string
  password: string
  remember: boolean
}

const Login = () => {

  const { setUser, user, isSuccess } = useAuthContext();

  const navigate = useNavigate();

  const [ isVisiblePassword, setIsVisiblePassword ] = useState(false);

  const [ isLoading, setIsLoading ] = useState(false);

  const [ error, setError ] = useState(false);

  const [ signIn ] = useSignInMutation()

  const { t } = useTranslation()

  const { register,  handleSubmit, formState: { errors } } = useForm<LoginForm>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      [LOGIN_FORM.REMEMBER]: true,
    }
  })

  useEffect(() => {
    if (isSuccess && user) {
      navigate(`/${ROUTES.CABINET}/${ROUTES.CALCULATOR}`, { replace: true });
    }
  }, [ user, isSuccess ])

  const checkIsValid = () => isEmpty(errors)

  const onSubmit = handleSubmit (async (values) => {

    setIsLoading(true);

    try {
      const value = {
        email: values.email,
        password: values.password,
      };

      const response: any = await signIn(value)

      if (response?.error) {
        throw response?.error;
      } else {
        // set the token
        setToken(response?.data.jwt);

        // set the user
        setUser(response?.data.user);

        navigate(`/${ROUTES.CABINET}`, { replace: true });
      }
    } catch (error) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  })

  return (
    <form className='auth' onSubmit={onSubmit}>
      <Card>
        <CardContent className='auth__card'>
          <Typography variant='h5' gutterBottom>
            {t('common__text__login')}
          </Typography>
          <Typography variant='body2' gutterBottom>
            {t('actions__message__enterEmailAndPass')}
          </Typography>
          <TextField
            {...register(LOGIN_FORM.EMAIL, { required: t('common__text__isRequired'), pattern: { value: EMAIL_PATTERN, message: t('errors__message__incorrectEmail') } })}
            error={Boolean(errors[LOGIN_FORM.EMAIL])}
            variant='outlined'
            label={t('common__text__email')}
            helperText={errors[LOGIN_FORM.EMAIL]?.message}
            sx={{
              marginTop: '16px',
            }}
            autoComplete='email'
          />
          <TextField
            {...register(LOGIN_FORM.PASSWORD, { required: t('common__text__isRequired'), minLength: { value: 6, message: t('common__text__minLength') } })}
            error={Boolean(errors[LOGIN_FORM.PASSWORD])}
            helperText={errors[LOGIN_FORM.PASSWORD]?.message}
            variant='outlined'
            label={t('common__text__password')}
            type={isVisiblePassword? 'text' : 'password'}
            sx={{
              marginTop: '22px',
            }}
            InputProps={{
              endAdornment: <InputAdornment position='start' onClick={() => setIsVisiblePassword(!isVisiblePassword)} className='auth__input-adornment'>{isVisiblePassword ? <VisibilityOffIcon/> : <VisibilityIcon/>}</InputAdornment>,
            }}
            autoComplete='password'
          />
          <Link href='/forgot-password' className='auth__forget-password' sx={{
            marginTop: '8px',
          }}>{t('actions__message__forgotPassword')}</Link>
          <FormGroup>
            <FormControlLabel
              {...register(LOGIN_FORM.REMEMBER)}
              control={<Checkbox defaultChecked />}
              label={t('actions__text__rememberMe')}
            />
          </FormGroup>
          <Button
            variant='contained'
            sx={{
              marginTop: '12px',
              marginBottom: '4px',
            }}
            disabled={!checkIsValid() || isLoading}
            type='submit'
          >{isLoading ? t('common__message__loadingText') : t('common__text__login')}</Button>
          {/*<Typography variant='subtitle1' gutterBottom align='center'>*/}
          {/*  {t('actions__message__notAMember')} <Link href='/register'>{t('common__text__registration')}</Link>*/}
          {/*</Typography>*/}
          {error && <Notification type={NOTIFICATION_TYPE.ERROR} title={t('errors__message__commonError')} />}
        </CardContent>
      </Card>
    </form>
  )
}

export default Login
