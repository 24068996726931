import React from "react";
import moment from "moment";

import Typography from '@mui/material/Typography';
import {useTranslation} from "react-i18next";
import {COLORS} from "../../../../../config/constants";
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import CheckIcon from '@mui/icons-material/Check';
import {supportedLngs} from "../../../../../config/i18";

type Props = {
    anchorEl: HTMLElement | null
    handleCloseMenu(): void
}

const LanguageMenu = ({ anchorEl, handleCloseMenu } : Props) => {

  const { t } = useTranslation()

  const { i18n } = useTranslation();

  React.useEffect(() => {
    const savedLocale = localStorage.getItem('locale') || 'en';
    i18n.changeLanguage(savedLocale);
    moment.locale(savedLocale);
  }, [ i18n ]);

  const handleChangeLanguage = (value: string) => {
    i18n.changeLanguage(value);
    moment.locale(value);
    localStorage.setItem('locale', value);
    window.location.reload();
  }

  return (
    <Menu
      id='menu-language'
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}
      sx={{
        '.MuiMenuItem-root': {
          minHeight: 30
        },
        '.MuiList-root': {
          width: 240,
        },
      }}
    >
      <div className='app-bar__topContainer'>
        <Typography variant='body1' sx={{fontWeight: 500, color: COLORS.BLACK_DARK}}>
          {t('settings__text__interfaceLanguage')}
        </Typography>
      </div>
      <Divider sx={{marginBottom: 0, marginTop: '1px'}}/>
      <div className='app-bar__menuContainer app-bar__menuContainer_language'>
        {Object.entries(supportedLngs).map(([ code, name ]) => {
          const isCurrentLanguage = code === i18n.resolvedLanguage;
          return (
            <MenuItem sx={{padding: '8px 16px'}}  onClick={() => handleChangeLanguage(code)} key={code}>
              {isCurrentLanguage && <CheckIcon sx={{color: 'rgba(0, 0, 0, 0.54)'}}/>}
              <Typography variant='body2' sx={{marginLeft: isCurrentLanguage ? 4 : 7, color: COLORS.BLACK_DARK}}>
                {name}
              </Typography>
            </MenuItem>
          )
        })}
      </div>
    </Menu>
  )
}

export default LanguageMenu
