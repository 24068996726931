import React, {useState} from "react";
import cx from "classnames";
import {useTranslation} from "react-i18next";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup";
import Divider from "@mui/material/Divider";

import {COLORS} from "../../../../../../../config/constants";

import ShowMoreMenu from "../../../../../../common/showMoreMenu";
import './additional-company-card.scss'
import {
  useRemoveExtraServicesByOrderIdMutation,
  useSetExtraServicesByOrderIdMutation,
  useUserExtraServices,
} from "../../../../../../../api/calc";
import {useDispatch} from "react-redux";
import {useGetCalcIdFromRoute} from "../../../../../../../utils/hooks";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Button from "@mui/material/Button";


type Props = {
    isActive?: boolean;
    company: ExtraService;
    userServices?: number[];
    refetchGetExtraServices?(): void
}

type CompanyCardServiceItemProps = {
    isActive?: boolean;
    service: ExtraServiceItem;
    index: number;
    refetchGetExtraServices?(): void;
}

const CompanyCardServiceItem = ({ isActive=false, service, index, refetchGetExtraServices } : CompanyCardServiceItemProps ) => {

  const [ isChecked, setIsChecked ] = useState(false);

  const [ removeExtraServices ] = useRemoveExtraServicesByOrderIdMutation()
  const [ setExtraServices ] = useSetExtraServicesByOrderIdMutation()

  const { t } = useTranslation()

  const orderId = useGetCalcIdFromRoute()
  const userExtraServices = useUserExtraServices()

  const handleRemove = async () => {
    await removeExtraServices({
      orderId: orderId,
      service_id: [ service.id ]
    })

    if (refetchGetExtraServices){
      refetchGetExtraServices();
    }
  }

  const getTextActiveItem = () => <div className='additional-company-card__service-item service-item'>
    <Typography
      variant='body2'
      sx={{
        color: COLORS.BLACK,
        maxWidth: '80%'
      }}
    >
      {service.name}
      {service.price_description && <span className='service-item__additionalInfo'>{` (${service.price_description})`}</span>}
    </Typography>
    <Typography variant='body2' sx={{
      color: COLORS.BLACK,
      display: 'flex',
      alignItems: 'center',
    }}>
      {service.price} {service.currency}
      <div className='service-item__showMore'>
        <ShowMoreMenu menuItems={[ {
          title: t('dictionaries__text__additional__deleteFromTheSelected'),
          onClick: handleRemove
        } ]}/>
      </div>
    </Typography>
  </div>;

  const handleChangeCheckbox = async () => {

    const hasServiceId = userExtraServices?.some(extraService =>
      extraService.services.some(extraServiceItem =>
        extraServiceItem.id === service.id)
    ) ||  false;

    let serviceIdsToSend = userExtraServices?.flatMap(extraService =>
      extraService.services.map(service => service.id)
    ) || [];

    if (hasServiceId) {
      serviceIdsToSend = serviceIdsToSend.filter(id => id !== service.id);
    } else {
      serviceIdsToSend.push(service.id);
    }

    setIsChecked(!isChecked);

    await setExtraServices({
      orderId: orderId,
      service_id: serviceIdsToSend
    });

  }

  return <>
    <Divider sx={{marginTop: 0}}/>
    {isActive ? <div className='service-item__title'>{getTextActiveItem()}</div> :
      <div className={cx('service-item__inactive', {
        'service-item__inactive_checked': isChecked,
      })}>
        <Typography
          variant='body2'
          sx={{
            color: COLORS.BLACK,
            maxWidth: '80%'
          }}
        >
          {`${index + 1}. `}{service.name}
          {service.price_description && <span className='service-item__additionalInfo'>{` (${service.price_description})`}</span>}
        </Typography>
        <Button
          variant='outlined'
          className='add-product__addFieldButton'
          sx={{
            minWidth: 108,
            minHeight: 36,
          }}
          onClick={() => handleChangeCheckbox()}
          startIcon={isChecked ? <CheckCircleIcon /> : <AddIcon/>}
        >{`${service.price} ${service.currency}`}</Button>
      </div>
    }
  </>
}


const CompanyCard = ({isActive, company, userServices, refetchGetExtraServices}: Props) => {

  const { t } = useTranslation()

  const activeServices = company.services.filter(service => !userServices?.includes(service.id));

  const orderId = useGetCalcIdFromRoute()

  const [ removeExtraServices ] = useRemoveExtraServicesByOrderIdMutation()

  const handleRemove = async () => {
    await removeExtraServices({
      orderId: orderId,
      company_id: company.id
    })

    if (refetchGetExtraServices){
      refetchGetExtraServices();
    }
  }

  return <Card className={cx('additional-company-card', {
    'additional-company-card_active': isActive,
  })} sx={{background: isActive ? COLORS.LIME : COLORS.WHITE, borderRadius: 0, boxShadow: 'none' }}>
    <CardContent sx={{
      padding: 0, '&:last-child': {
        paddingBottom: 0
      }
    }}>
      <div className='additional-company-card__title'>
        <div className='additional-company-card__left'>
          <span className='additional-company-card__logo'>{company.name[0]}</span>
          <Typography variant='subtitle2'>
            {company.name}
          </Typography>
        </div>
        <Typography variant='h6' sx={{ lineHeight: 1.2}} className='additional-company-card__right'>
          {company.cost_total} {company.currency}
          {isActive && <ShowMoreMenu menuItems={[ {
            title: t('common__text__remove'),
            onClick: handleRemove
          } ]}/>}
        </Typography>
      </div>
      <FormGroup sx={{ marginLeft: 3, marginRight: 3, marginBottom: 3 }}>
        {activeServices.map((service: ExtraServiceItem, index) =>
          <CompanyCardServiceItem
            service={service}
            index={index}
            key={index}
            isActive={isActive}
            refetchGetExtraServices={refetchGetExtraServices}
          />)}
        <Divider sx={{ marginTop: 0 }} />
      </FormGroup>
    </CardContent>
  </Card>
}

export default CompanyCard
