import React from "react";
import {useTranslation} from "react-i18next";

import Typography from "@mui/material/Typography";

import {COLORS} from "../../../../../../../../../config/constants";
import { RecipientLong } from "../../../../../../../../../assets/svg";
import {numberWithSpaces} from "../../../../../../../../../utils/helpers";

type Props = {
    invoice?: InvoiceParamsOutput,
    index: number
}

const InvoiceTitle = ({invoice, index} : Props) => {

  const { t } = useTranslation()

  const invoiceId = invoice?.id;

  return (
    <div className='invoice-title'>
      <div className='invoice-title__left'>
        <RecipientLong color={invoiceId ? COLORS.GREEN : COLORS.SLIGHTLY_GRAY}/>
        <Typography variant='h6' className='invoice-title__name'>
          {`${index + 1}. ${t('common__text__invoice')} № ${invoice?.invoice_num}`}
        </Typography>
      </div>
      {invoiceId && (
        <div className='invoice-title__right'>
          <Typography variant='h6'>
            {numberWithSpaces(invoice?.price_total)} USD
          </Typography>
        </div>
      )}
    </div>
  )
}

export default InvoiceTitle
