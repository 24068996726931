import React from "react";
import {useTranslation} from "react-i18next";

import {CONTAINERS_FORM, METHODS} from "../../../../cabinet/calculator/components/invoices-and-products/components/summary";
import {numberWithSpaces} from "../../../../../utils/helpers";

export const getPackagingInfo = (isCompleted: boolean, invoices?: InvoiceParamsOutput[],  summaryOrder?: SummaryParamsOutput | Record<string, never>,) => {

  const { t } = useTranslation();

  if (!invoices) {
    return [];
  }

  const data = [
    invoices?.map((invoice, index) => (
      {
        label: `${index + 1}. ${invoice.description || `${t('common__text__invoice')} №${invoice.invoice_num}`} (${invoice.product_count})`,
        value: `${numberWithSpaces(invoice.price_total)} USD`,
      }
    )),
    [
      ...(summaryOrder?.products_count
        ? [
          {
            label: t('calc__text__packaging__totalProducts'),
            value: summaryOrder.products_count,
          },
        ]
        : []),
      ...(summaryOrder?.price_total
        ? [
          {
            label: t('calc__text__rightPanel__totalCostOfGoods'),
            value: summaryOrder?.price_total,
          },
        ]
        : []),
      ...(summaryOrder?.volume_total
        ? [
          {
            label: t('common__text__totalVolume'),
            value: <>{summaryOrder?.volume_total} {t('units__text__meter')}<sup>3</sup></>,
          },
        ]
        : []),
      ...(summaryOrder?.net_weight_total
        ? [
          {
            label: t('common__text__netGrossWeight'),
            value: <>{summaryOrder?.net_weight_total} {t('units__text__kg')} / {summaryOrder?.gross_weight_total} {t('units__text__kg')}</>,
          },
        ]
        : []),
    ],
  ];

  if (summaryOrder && isCompleted && summaryOrder) {

    const isCombinedCargo = summaryOrder.delivery_type === METHODS.COMBINED_CARGO || false;

    const getCombinedCargoData = () => [
      {
        label:  t('calc__text__delivery__airfreight'),
        value: `${summaryOrder?.volume_weight.avia} ${t('units__text__kg')}`,
      },
      {
        label:  t('calc__text__delivery__seafreight'),
        value: `${summaryOrder?.volume_weight.sea} ${t('units__text__kg')}`,
      },
      {
        label:  t('calc__text__delivery__trucking'),
        value: `${summaryOrder?.volume_weight.auto} ${t('units__text__kg')}`,
      },
      {
        label:  t('calc__text__delivery__railwayFreight'),
        value: `${summaryOrder?.volume_weight.rails} ${t('units__text__kg')}`,
      }
    ]

    const getContainersData = () => [
      {
        label:  '20 Ft',
        value: summaryOrder?.containers_user?.[CONTAINERS_FORM.FT_20] || '-' ,
      },
      {
        label:  '40 Ft',
        value: summaryOrder?.containers_user?.[CONTAINERS_FORM.FT_40] || '-' ,
      },
      {
        label:  '40 Ft High cube',
        value: summaryOrder?.containers_user?.[CONTAINERS_FORM.FT_40_HIGH_CUBE] || '-' ,
      },
    ]

    data.push([
      {
        label:  t('calc__text__packaging__deliveryMethod'),
        value: <>{isCombinedCargo ? t('calc__text__packaging__combinedCargo') : t('calc__text__packaging__containers')}</>,
      },
      // @ts-ignore
      ...(isCombinedCargo ? getCombinedCargoData() : []),
      // @ts-ignore
      ...(!isCombinedCargo ? getContainersData() : [])
    ])
  }

  return data;

}
