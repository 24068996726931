import React, { ReactNode } from 'react'
import Typography from "@mui/material/Typography";
import COLORS from 'styles/colors.scss'
import cx from "classnames";
import MenuButton from "../menuButton";
import {useTranslation} from "react-i18next";
import './completed.scss'

type CompletedParams = {
    info: {
        label: string
        value: ReactNode
    }[]
    onEdit?(id?: number): void,
    onRemove?(id?: number): void,
    caption?: ReactNode
    id?: number
    isFullRow?: boolean
    children?: React.ReactNode
    noPadding?: boolean
    noBackground?: boolean
    hasThreeRows?: boolean
    preInfo?: React.ReactNode
    hasNoValues?: boolean
    bottom?:  React.ReactNode,
}

const Completed = (props: CompletedParams) => {

  const { t } = useTranslation()

  const { info, onRemove, onEdit, id, children,
    isFullRow, noPadding, noBackground, preInfo, hasThreeRows,
    hasNoValues, bottom } = props;

  const menuOptions = [];

  if (onEdit){
    menuOptions.push({
      title: t('actions__text__edit'),
      onClick: () => onEdit(id),
    });
  }

  if (onRemove){
    menuOptions.push({
      title: t('actions__text__delete'),
      onClick: () => onRemove(id),
    });
  }

  if (!info) return <></>

  return <div className={cx('completed', {
    'completed_noPadding': noPadding,
    'completed_noBackground': noBackground,
  })}>
    {props.caption && <div className='completed__caption-row'>
      {props.caption &&
              <Typography variant='subtitle1' sx={{color: `${COLORS.BLACK_DARK}`}}>{props.caption}</Typography>}
      {onEdit && <div className='completed__menu'>
        <MenuButton options={menuOptions}/>
      </div>}
    </div>}
    {preInfo}
    {info.length > 0 && (
      <ul className={cx('completed__list', {
        'completed__list-oneRow': isFullRow,
        'completed__list-threeRows': hasThreeRows,
        'completed__list-indent': props.caption,
        'completed__list-small': Boolean(onEdit),
        'completed__list-noValues': hasNoValues
      })}>
        {info.map((item, index) => (
          <li className='completed__list-item' key={index}>
            {<Typography variant='body2' sx={{color: COLORS.LIGHT_TEXT_SECONDARY}}>{item.label}</Typography>}
            <Typography variant='body2' sx={{color: COLORS.BLACK_DARK}}>{item.value}</Typography>
          </li>
        ))}
        {children}
      </ul>
    )}
    {bottom}
  </div>
}

export default Completed
